import {
  IColumns,
  ICondition,
  IDoctor,
  IFlowAction,
  IFlowItem,
  IFlowRule,
  IForm,
  IFormAlertConfig,
  IMedspecField,
  IMedspecSlide,
  IMedspecSlideOut,
  IMedspecValidator,
  IParameterGroup,
  IPostRule,
  IPostRuleOut,
} from "@packages/types";
import { AxiosResponse } from "axios";

import { isFormDetailsValid, isFormFlowValid } from "../validation";

export type IResponseCallback<IDataType> = (
  resp: AxiosResponse<object>,
  data: IDataType,
) => void;
export type IResponse<T> = { resp: AxiosResponse; result: T };
export type IArrayResponse<T> = { resp: AxiosResponse; result: T[] };

// Quick wrapper to catch errs when parsing arrays
export function serialiseArrayResponse<T>(
  vals: object[],
  handler: (val: object) => T,
): T[] {
  const res: T[] = [];
  vals.forEach((val) => {
    res.push(handler(val));
  });
  return res;
}

// Quick wrapper for catching all other serialisation errors
export function serialiseObjectResponse<T>(
  val: object,
  handler: (val: object) => T,
): Promise<T> {
  return new Promise((resolve, reject) => {
    try {
      resolve(handler(val));
    } catch (e) {
      reject(e);
    }
  });
}

export function serialiseDoctor(obj: any): IDoctor {
  return {
    email: obj["email"],
    phone: obj["phone"],
    createdAt: obj["created_at"],
    firstName: obj["first_name"],
    lastName: obj["last_name"],
  };
}

export const getFirstValidForm = (forms?: IForm[]): IForm | undefined => {
  return forms?.find((f) => isFormValid(f, forms));
};

export const isFormValid = (form?: IForm, forms?: IForm[]): boolean => {
  if (!form || !forms) {
    return false;
  }
  return !(
    Object.entries(form.slides).length < 1 ||
    !isFormFlowValid(form) ||
    !isFormDetailsValid(form, forms)
  );
};

export function serialiseAllForms(obj: object): IForm[] {
  return Object.entries(obj).map(([formID, form]) =>
    serialiseForm(formID, form),
  );
}

export function serialiseForm(formID: string, obj: any): IForm {
  return {
    id: formID,
    ...obj,
    name: obj["name"],
    description: obj["description"],
    endMessage: obj["end_message"],
    version: obj["version"],
    actions: serialiseDefinedActions(obj["actions"]),
    slides: serialiseMedspecSlides(obj["slides"]),
    flow: obj["flow"] ? serialiseFlowItems(obj["flow"]) : undefined,
    postConditions: serialisePostConditions(obj["postConditions"]),
    alert_config: obj["alert_config"]
      ? serialiseAlertConfig(obj["alert_config"])
      : undefined,
    templateID: obj["template_id"],
    rawSlides: obj["slides"],
    hl7: obj["hl7"],
  };
}

export function serialiseAlertConfig(config: any): IFormAlertConfig {
  return {
    enabled: config["enabled"],
    name_slide: config["name_slide"],
    name_field: config["name_field"],
  };
}

export function serialisePostConditions(conditions: any[]): {
  [id: string]: IPostRule;
} {
  const obj = {};
  conditions.forEach((condition) => {
    obj[condition["id"]] = serialisePostCondition(condition);
  });
  return obj;
}

export function serialisePostCondition(condition: any): IPostRule {
  return {
    id: condition["id"],
    conditions: serialiseFlowConditions(condition["conditions"]),
    then: serialiseFlowActions(condition["then"]),
  };
}

export const PostConditionOut = (
  key: string,
  condition: IPostRule,
): IPostRuleOut => {
  return {
    id: key,
    conditions: condition.conditions,
    then: condition.then,
  };
};

export function serialiseDefinedActions(actions: object): {
  [id: string]: IParameterGroup;
} {
  const obj = {};
  Object.entries(actions).forEach(([k, v]: [string, any]) => {
    obj[k] = serialiseParameterGroups(v);
  });
  return obj;
}

export function serialiseParameterGroups(groups: object): IParameterGroup {
  const obj = {};
  Object.entries(groups).forEach(([k, v]: [string, any]) => {
    obj[k] = v;
  });
  return obj;
}

export function serialiseFlowItems(items: any[]): IFlowItem[] {
  return items.map((item) => serialiseFlowItem(item));
}

export function serialiseFlowItem(item: any): IFlowItem {
  return {
    src: item["src"],
    defaultDest: item["defaultDest"],
    rules: serialiseFlowRules(item["rules"]),
  };
}

export function serialiseFlowRules(rules: any[]): IFlowRule[] {
  return rules.map((rule) => ({
    id: rule["id"],
    external: rule["external"],
    conditions: serialiseFlowConditions(rule["conditions"]),
    then: serialiseFlowActions(rule["then"]),
  }));
}

export function serialiseFlowActions(actions: any[]): IFlowAction[] {
  return actions.map((action) => ({
    action: action["action"],
    arguments: serialiseActionArguments(action["arguments"]),
  }));
}

export function serialiseActionArguments(args: any): any {
  if (typeof args === "object") {
    const obj = {};
    Object.entries(args).forEach(([k, v]: [string, any]) => {
      obj[k] = v;
    });
    return obj;
  } else {
    return args;
  }
}

export function serialiseFlowConditions(conditions: any[]): ICondition[] {
  return conditions.map((condition) => ({
    field: condition["field"],
    operator: condition["operator"],
    argument: condition["argument"],
  }));
}

export function serialiseMedspecValidators(
  validators: any[],
): IMedspecValidator[] {
  return validators.map((validator) => ({
    method: validator["method"],
    condition: validator["condition"],
  }));
}

export function serialiseMedspecSlides(slides: any[]): {
  [id: string]: IMedspecSlide;
} {
  const obj = {};
  slides.forEach((slide) => {
    obj[slide["id"]] = serialiseMedspecSlide(slide);
  });
  return obj;
}

export function serialiseMedspecSlide(slide: any): IMedspecSlide {
  return {
    id: slide["id"],
    name: slide["name"],
    fields: serialiseMedspecFields(slide["fields"]),
    description: slide["description"],
    startingSlide: slide["starting_slide"],
  };
}

export const toMedspecSlideOut = (
  slideKey: string,
  slide: IMedspecSlide,
): IMedspecSlideOut => {
  return {
    id: slideKey,
    name: slide.name,
    description: slide.description,
    fields: slide.fields ?? [],
    starting_slide: slide.startingSlide,
  };
};

export function serialiseMedspecFields(fields: any[]): IMedspecField[] {
  return fields.map((field) => ({
    id: field["id"],
    name: field["name"],
    type: field["type"],
    placeholder: field["placeholder"],
    validators: serialiseMedspecValidators(field["validators"]),
    choices: field["choices"],
  }));
}

export function serialiseColumns(obj: object): IColumns {
  const columns: IColumns = {};
  Object.entries(obj).forEach(([key, value]) => {
    columns[key] = value;
  });
  return columns;
}
