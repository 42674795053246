import {
  createPatient,
  deletePatient,
  fetchAllPatients,
  fetchPatientForDoctor,
} from "@packages/api";
import { useAuthToken, useRole } from "@packages/auth";
import { IDoctorPatients, IPatient, IPatientCreate } from "@packages/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useError } from "contexts/AlertContext";

import { isRoleAdmin } from "../../utils/roles";

export function usePatients(doctor: string | undefined) {
  const roles = useRole();
  const { token } = useAuthToken();
  const { error } = useError();

  return useQuery<IDoctorPatients, undefined>(
    ["patients"],
    async () => {
      return isRoleAdmin(roles) || !doctor
        ? fetchAllPatients(token)
        : fetchPatientForDoctor(token, doctor);
    },
    { onError: error },
  );
}

export function useAddPatient(doctor: string) {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();

  return useMutation(
    async (newPatient: IPatientCreate) =>
      createPatient(token, doctor, newPatient),
    {
      onSuccess: (data: IPatient) => {
        queryClient.setQueryData(
          ["patients"],
          (cachedData: IDoctorPatients | undefined) => {
            if (!cachedData) {
              return cachedData;
            }
            if (!cachedData[doctor]) {
              cachedData[doctor] = [];
            }
            cachedData[doctor].push(data);
            return cachedData;
          },
        );
      },
      onError: error,
    },
  );
}

export function useDeletePatient() {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();

  return useMutation(
    async ({ patient, doctor }: { patient: string; doctor: string }) =>
      deletePatient(token, doctor, patient),
    {
      onSuccess: (data: { doctor: string; patientId: string }) => {
        queryClient.setQueryData(
          ["patients"],
          (cachedData: IDoctorPatients | undefined) => {
            if (!cachedData) {
              return cachedData;
            }
            cachedData[data.doctor] = cachedData[data.doctor].filter(
              (patient) => patient.id !== data.patientId,
            );
            return cachedData;
          },
        );
      },
      onError: error,
    },
  );
}
