import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRole } from "@packages/auth";
import { IColumns } from "@packages/types";
import { useAllForms } from "api/hooks/schema";
import { useStoredColumns, useUpdateStoredColumns } from "api/hooks/user";

import { getDefaultColumns } from "components/doctor/Patient/PatientColumns";

// Column contexts
interface IColumnContext {
  columns: IColumns;
  setColumns: (columns: IColumns) => void;
}

export const ColumnContext = createContext<IColumnContext>({
  columns: {},
  setColumns: () => {
    return;
  },
});

export function useColumns(): IColumnContext {
  const ctx = useContext(ColumnContext);
  if (ctx === undefined) {
    throw new Error("useColumns must be used within a GenericModalsProvider");
  }
  return ctx as IColumnContext;
}

export function ColumnContextProvider({ children }: { children: ReactNode }) {
  const { data: forms } = useAllForms();
  const [columns, setColumns] = useState({});
  const { data: storedCols } = useStoredColumns();
  const { mutate: updateStoredCols } = useUpdateStoredColumns();
  const roles = useRole();

  // Assign default columns on login
  useEffect(() => {
    const defaultFormCols = forms?.reduce(
      (result, form) => ({
        ...result,
        [form.id]: getDefaultColumns(roles ?? []),
      }),
      {},
    );

    setColumns({
      ...defaultFormCols,
      ...storedCols,
    });
  }, [forms, storedCols]);

  const onChange = (cols: IColumns) => {
    setColumns(cols);
    // Update backend
    updateStoredCols(cols);
  };

  return (
    <ColumnContext.Provider value={{ columns, setColumns: onChange }}>
      {children}
    </ColumnContext.Provider>
  );
}
