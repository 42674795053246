import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";

export const Settings = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const tabs = ["Billing", "Usage"];
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const navigateToTab = (tabName) =>
    navigate(`/settings/${tabName.toLowerCase().replace(" ", "-")}`);

  useEffect(() => {
    navigateToTab(tabs[tabIndex]);
  }, [tabIndex]);

  return (
    <VStack px="6" py="2" ml="0.5" mr="1" overflow="hidden">
      <Stack width="100%" className="text-md font-semibold">
        <Tabs
          isLazy
          index={tabIndex}
          onChange={handleTabsChange}
          onClick={(event) => {
            const tabElement = event.target as HTMLElement;
            if (
              tabElement?.className &&
              tabElement.className.includes &&
              tabElement.className.includes("tabs") &&
              tabElement.textContent
            ) {
              navigateToTab(tabElement.textContent);
            }
          }}
        >
          <TabList>
            {tabs.map((tab) => (
              <Tab key={tab}>{tab}</Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab}>
                <Outlet />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Stack>
    </VStack>
  );
};
