import { PaymentIntent } from "@packages/types";

import { apiClient, withAuth } from "../client";
export const fetchPaymentConfig = async (token: string): Promise<any> => {
  const { data } = await apiClient.get("/payment/config", withAuth(token));
  return data;
};

export const createPaymentIntent = async (
  token: string,
  paymentIntent: PaymentIntent,
): Promise<any> => {
  const { data } = await apiClient.post(
    "/payment/create-payment-intent",
    paymentIntent,
    withAuth(token),
  );
  return data;
};

export const fetchBalance = async (
  token: string,
  email: string,
): Promise<{
  balance: number;
  formattedBalance: string;
}> => {
  const { data } = await apiClient.get(
    `/payment/balance/${email}`,
    withAuth(token),
  );
  return data;
};

interface Transaction {
  payment_method_details: { type: "card"; card: any };
  created: number;
  amount: number;
  user: string;
  payment_intent: string;
  type: string;
  currency: string;
}

export const fetchTransaction = async (
  token: string,
  email: string,
): Promise<{
  total: number;
  formattedTotal: string;
  transactions: Transaction[];
}> => {
  const { data } = await apiClient.get(
    `/payment/transaction/${email}`,
    withAuth(token),
  );
  return data;
};
