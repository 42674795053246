// File for storing world-unique configuration parameters

const variables = {
  auth: {
    auth0Audience: "https://api.otoshealth.com/v2",
    auth0ProdDomain: "otoshealth.au.auth0.com",
    auth0DevDomain: "otoshealthdev.au.auth0.com",
  },
  v2Api: {
    tenant: "pioneerhealth.otoshealth.com",
    urlDev: "http://localhost:8079/v2",
    urlTesting: "https://api-testing.otoshealth.com/v2",
    urlProd: "https://api2.otoshealth.com/v2",
  },
  adminApi: {
    urlDev: "http://localhost:8888",
    urlTesting: "https://admin-api-testing.otoshealth.com",
    urlProd: "https://admin-api.otoshealth.com",
  },
  doctor: {
    alertTimeout: 10,
    smsMaxMessageLength: 800,
    userGuideLink:
      "https://otoshealth.sharepoint.com/:b:/s/OtosHealth/ERGmDsYoJw9Il-OHLNYbQ5cBbRsDI-G3muERWQjJH3yZIg?e=5Mv3H3",
  },
};

export default variables;

export function getAPIURL(env: "dev" | "testing" | "prod"): {
  v2Endpoint: string;
  adminEndpoint: string;
} {
  switch (env) {
    case "dev":
      return {
        v2Endpoint: variables.v2Api.urlDev,
        adminEndpoint: variables.adminApi.urlDev,
      };
    case "testing":
      return {
        v2Endpoint: variables.v2Api.urlTesting,
        adminEndpoint: variables.adminApi.urlTesting,
      };
    default:
      return {
        v2Endpoint: variables.v2Api.urlProd,
        adminEndpoint: variables.adminApi.urlProd,
      };
  }
}
