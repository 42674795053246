import { useEffect, useState } from "react";
import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IResponse } from "@packages/types";
import { useAllForms } from "api/hooks/schema";
import { useGenericModals } from "contexts/GenericModals";
import { useModalState } from "contexts/GlobalModalState";
import dateFormat from "dateformat";
import { renderPatient } from "utils/medspecSchema";
import { getSlidesOrdered } from "utils/patientList";

import { KeyValueField } from "components/generic/KeyValueField";

import { SlideFieldsItem } from "./SlideFieldsItem";

export function ViewPatientModal() {
  const genericModals = useGenericModals();
  // Get the current patient from the state
  const { activePatient, setActivePatient: setPatient } = useModalState();
  const { activeFormID } = useModalState();
  const { data: forms } = useAllForms();
  const form = forms?.find((f) => f.id === activeFormID);
  const startingSlideID = form
    ? Object.keys(form.slides).find(
        (key) => form.slides[key].startingSlide === true,
      )
    : undefined;
  const [response, setResponse] = useState<IResponse | undefined>(undefined);

  const patient = renderPatient(activePatient, form);

  // Display the first response by default
  useEffect(() => {
    if (patient?.response) {
      setResponse(patient.response[0]);
    }
  }, [patient]);

  const onClose = () => setPatient(undefined);

  if (response !== undefined) {
    return (
      <Modal isOpen={patient !== undefined} onClose={onClose} size="4xl">
        {patient === undefined ? null : (
          <>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <HStack mr="8">
                  <p className="grow">Form Submission</p>
                  <Button
                    colorScheme="blue"
                    onClick={() =>
                      genericModals.openExportModal(patient, response, form)
                    }
                  >
                    <FontAwesomeIcon icon={["fas", "file-export"]} />
                    <p className="ml-2">Export</p>
                  </Button>
                </HStack>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack alignItems="stretch">
                  <Box
                    w="100%"
                    border="1px"
                    borderColor="gray.300"
                    rounded="md"
                    p="4"
                    pt="2"
                  >
                    <p className="mb-4 text-xl font-semibold">
                      General Information
                    </p>
                    <HStack alignContent="flex-start">
                      <div className="grow">
                        <KeyValueField
                          keyName="Phone"
                          value={patient.phone.toString()}
                          icon={["fas", "phone"]}
                        />
                      </div>
                      <div className="grow">
                        <KeyValueField
                          keyName="Submission Date"
                          value={dateFormat(
                            response.createdAt,
                            "hh:MM:ss TT, dd/mm/yyyy",
                          )}
                          icon={["fas", "calendar"]}
                        />
                        <KeyValueField
                          keyName="Fields Present"
                          value={Object.keys(response.slides).length.toString()}
                          icon={["fas", "keyboard"]}
                        />
                      </div>
                    </HStack>
                    {patient?.data?.counter !== undefined && (
                      <VStack p="1" my="3" alignItems="flex-start">
                        <HStack>
                          <FontAwesomeIcon
                            icon={["fas", "flag"]}
                            className="my-auto"
                          />
                          <p className="font-semibold">Counter Values:</p>
                        </HStack>
                        <Box w="full" ml="2" overflowX="auto">
                          <HStack w="fit-content" spacing="10" mb="4">
                            {Object.entries(patient.data.counter).map(
                              (counter, key) => (
                                <Button
                                  key={key}
                                  colorScheme="blue"
                                  pointerEvents="none"
                                  size="sm"
                                  fontSize="md"
                                  paddingX="6"
                                >
                                  <p className="mr-2">{counter[0]}:</p>
                                  {counter[1]}
                                </Button>
                              ),
                            )}
                          </HStack>
                        </Box>
                      </VStack>
                    )}
                  </Box>
                  <Wrap className="!mb-5">
                    {form !== undefined &&
                      startingSlideID !== undefined &&
                      getSlidesOrdered(startingSlideID, form).map((key) => (
                        <SlideFieldsItem
                          key={key}
                          response={response}
                          slide={form.slides[key]}
                          slideID={key}
                        />
                      ))}
                  </Wrap>
                </VStack>
              </ModalBody>
            </ModalContent>
          </>
        )}
      </Modal>
    );
  }
  return null;
}
