import { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Code,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPatient, IResponse } from "@packages/types";
import { FieldTypes, IForm, IMedspecField } from "@packages/types";
import dateFormat from "dateformat";
import { renderFieldType } from "misc";
import {
  ExportFilter,
  getSlideFilterFunc,
  getSlidesOrdered,
  isFieldEmpty,
} from "utils/patientList";

import { HelpTooltip } from "components/generic/Tooltips/HelpTooltip";

interface IResponseExportProps {
  isOpen: boolean;
  onClose: () => void;
  schema: IForm | undefined;
  patient: IPatient | undefined;
  response: IResponse | undefined;
}

interface IExportTextProps {
  schema?: IForm;
  patient?: IPatient;
  response?: IResponse;
  filter?: ExportFilter;
}

function exportText({ schema, patient, response, filter }: IExportTextProps): {
  headerText: string;
  responseDetailsText: string;
  responseDetailsRender: ReactElement[];
} {
  if (!schema || !response) {
    return {
      headerText: "",
      responseDetailsText: "",
      responseDetailsRender: [<></>],
    };
  }

  const startingSlideID = schema
    ? Object.keys(schema.slides).find((s) => schema.slides[s].startingSlide)
    : undefined;

  // Filter to exclude incomplete slides
  const slidesCompleted = (slides: string[]) => {
    return slides.filter((slideID) => {
      return Object.keys(response.slides).some((key) => key.includes(slideID));
    });
  };

  const getField = (field: IMedspecField, component: string): string => {
    // Check if the person actually submitted the form
    if (response === undefined) {
      return `• ${field.name}: No value provided.\n`;
    }
    // Check if there is a value for the specified field
    const val = response.slides[`${component}_${field.id}`];
    if (val === undefined || val.length < 1) {
      return `• ${field.name}: No value provided.\n`;
    }
    // Render the field value
    switch (field.type) {
      case FieldTypes.MultiSelect:
        return `• ${field.name}:\n ${val.map((v) => `   - ${v}`).join("\n")}\n`;
      default:
        return `• ${field.name}: ${renderFieldType(val, field.type)}\n`;
    }
  };

  // Filter fields when the filter var is set
  const getSlides = (startingSlideID: string): string[] => {
    const slides = getSlidesOrdered(startingSlideID, schema).filter(
      getSlideFilterFunc(schema, response, filter),
    );

    return slidesCompleted(slides).map(
      (slideID) =>
        `${schema.slides[slideID]?.name}\n` +
        `${schema.slides[slideID]?.fields
          ?.map((field) => {
            switch (filter) {
              case ExportFilter.OnlyEntered:
                return isFieldEmpty(response?.slides[`${slideID}_${field.id}`])
                  ? ""
                  : getField(field, slideID);
              default:
                return getField(field, slideID);
            }
          })
          .join("")}\n`,
    );
  };

  let responseDetails;
  if (startingSlideID !== undefined) {
    let counter = "";
    if (
      patient?.data?.counter &&
      Object.keys(patient.data.counter).length > 0
    ) {
      counter = "Counter: \n";

      Object.entries(patient.data.counter).forEach(([key, value]) => {
        counter += `• ${key}: ${value}\n`;
      });
      counter += "\n";
    }

    responseDetails = [counter, ...getSlides(startingSlideID)];
  }

  return {
    headerText:
      "Form submission exported from Otos Health with patient consent.\n\n" +
      "Patient Details:\n\n" +
      `Phone: ${patient?.phone}\n` +
      `Appointment Time: ${
        !patient?.appointmentTime
          ? "Not provided."
          : dateFormat(patient?.appointmentTime, "dd/mm/yyyy HH:MM:ss")
      }\n\n` +
      "Response Details:\n\n",
    responseDetailsText: responseDetails?.join("\n") ?? "",
    responseDetailsRender: responseDetails?.map((slide) => (
      <div className="export-highlight" key={slide}>
        {slide}
      </div>
    )),
  };
}

export function ResponseExport({
  isOpen,
  onClose,
  schema,
  patient,
  response,
}: IResponseExportProps) {
  const [copied, setCopied] = useState<boolean>(false);
  const [filter, setFilter] = useState<ExportFilter | undefined>(undefined);

  const refreshButton = () => {
    setCopied(false);
  };

  useEffect(() => {
    refreshButton();
  }, [isOpen]);

  const { headerText, responseDetailsRender, responseDetailsText } = exportText(
    { schema, patient, response, filter },
  );

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(`${headerText}${responseDetailsText}`);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="mr-8">
          <HStack>
            <HStack className="!w-72">
              <p className="pl-2">Form Submission Export</p>
              <HelpTooltip
                className="ml-1"
                description="Copy and paste this submission to use in other practice software."
              />
            </HStack>
            <HStack className="grow" h="10" px="2" fontSize="md">
              <Select
                size="sm"
                className="grow"
                onChange={(e) =>
                  setFilter(
                    e.target.value == ""
                      ? undefined
                      : (e.target.value as ExportFilter),
                  )
                }
              >
                <option key={0} value={""}>
                  Showing All
                </option>
                {Object.values(ExportFilter).map((f, key) => (
                  <option key={key++} value={f}>
                    {f}
                  </option>
                ))}
              </Select>
            </HStack>
            <Button
              size="sm"
              onClick={handleCopy}
              {...(copied ? { colorScheme: "green" } : {})}
            >
              <FontAwesomeIcon
                className="w-4"
                icon={["fas", `${copied ? "check" : "clipboard"}`]}
              />
              <p className="pl-2">Copy to clipboard</p>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="10">
          <Code
            id="text"
            whiteSpace="pre-line"
            className="w-full p-2"
            paddingInlineStart="2"
            paddingInlineEnd="2"
          >
            {headerText}
            {responseDetailsRender}
          </Code>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
