import { useCallback, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { serialiseDeliveryStatus } from "@packages/api";
import { IPhoneDeliveryStatus } from "@packages/types";
import { useQueryClient } from "@tanstack/react-query";

const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
const WEBSOCKET_API_DOMAIN = import.meta.env.VITE_WEBSOCKET_API_DOMAIN;
const TENANT = import.meta.env.VITE_TENANT;
const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;

export const wsAuthQueryParams = ({ userEmail, token }) => ({
  UserEmail: userEmail || "",
  Tenant: TENANT,
  Token: token,
  Auth0Domain: AUTH0_DOMAIN,
  Auth0Audience: AUTH0_AUDIENCE,
});

export const useWS = (token: string, userEmail: string | undefined) => {
  const [ready, setReady] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (token && userEmail) {
      setReady(true);
    }
  }, [token, userEmail]);

  const getSocketUrl = useCallback((): Promise<string> => {
    return new Promise((resolve) => {
      if (ready) {
        resolve(`wss://${WEBSOCKET_API_DOMAIN}`);
      }
    });
  }, [ready]);

  useWebSocket(getSocketUrl, {
    queryParams: {
      UserEmail: userEmail || "",
      Tenant: TENANT,
      Token: token,
      Auth0Domain: AUTH0_DOMAIN,
      Auth0Audience: AUTH0_AUDIENCE,
    },
    retryOnError: true,
    shouldReconnect: () => true,
    onMessage: async (event) => {
      const { data } = JSON.parse(event.data);
      if (data.key?.includes("deliverability")) {
        await queryClient.setQueryData(
          ["sms-deliverability"],
          (cachedData: IPhoneDeliveryStatus | undefined) => {
            const rawData = data.record.value as {
              delivered_ts: string;
              mobile: string;
              queue_ts: string; //"2023-05-29T23:44:24.085Z"
              sent_ts: string;
              sid: string;
              status: string;
            };
            const newData = serialiseDeliveryStatus(rawData);
            if (!cachedData) {
              return {
                [newData.mobile]: [newData],
              };
            }
            if (!cachedData[newData.mobile]) {
              return {
                ...cachedData,
                [newData.mobile]: [newData],
              };
            }
            return {
              ...cachedData,
              [newData.mobile]: [
                ...cachedData[newData.mobile].filter(
                  (item) => item.sid !== newData.sid,
                ),
                newData,
              ],
            };
          },
        );
      }
      if (data?.source?.includes(`patient-response`)) {
        await queryClient.invalidateQueries({
          queryKey: ["patients"],
        });
      }
      if (data?.key?.includes(`otoshealth/schema/${TENANT}/forms`)) {
        await queryClient.invalidateQueries({
          queryKey: ["forms"],
        });
      }
    },
  });
};
