import {
  FormControl,
  FormErrorMessage,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MINIMUM_AMOUNT } from "../../../../constants";

const DEFAULT_AMOUNT = "0";

interface Prop {
  setIsCustomAmount: (isCustomAmount: boolean) => void;
  setCustomAmount: (customAmount: string) => void;
  customAmount: string;
  isCustomAmount: boolean;
  setSelectedAmount: (amount: number) => void;
}
export const CustomAmount = ({
  setIsCustomAmount,
  setCustomAmount,
  customAmount,
  isCustomAmount,
  setSelectedAmount,
}: Prop) => {
  const format = (val) => `$` + val;
  const parse = (val) => val.replace(/^\$/, "");

  return (
    <FormControl isInvalid={+customAmount < MINIMUM_AMOUNT && isCustomAmount}>
      <h2
        style={{
          paddingBottom: 15,
          display: "flex",
        }}
      >
        <span> Or custom amount:</span>
        {customAmount !== DEFAULT_AMOUNT && isCustomAmount && (
          <FontAwesomeIcon
            icon={["fas", "check"]}
            style={{
              marginLeft: 4,
              marginTop: 4,
              color: "green",
            }}
          />
        )}
      </h2>
      <NumberInput
        step={1}
        defaultValue={+DEFAULT_AMOUNT}
        min={0}
        onFocus={() => {
          if (+customAmount < MINIMUM_AMOUNT) {
            setCustomAmount(MINIMUM_AMOUNT.toString());
            setSelectedAmount(+MINIMUM_AMOUNT);
          }
        }}
        onChange={(valueString) => {
          if (+valueString <= +DEFAULT_AMOUNT) {
            return;
          }
          setIsCustomAmount(true);
          setCustomAmount(parse(valueString));
          setSelectedAmount(+parse(valueString));
        }}
        value={format(customAmount)}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormErrorMessage>Minimum amount is ${MINIMUM_AMOUNT}</FormErrorMessage>
    </FormControl>
  );
};
