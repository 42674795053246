/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
} from "@chakra-ui/react";
import globalVars from "@packages/config/globalVars";

export enum AlertTypes {
  Connection,
  Authentication,
}

interface IConnectionAlertProps {
  isOpen: boolean;
  onClose: () => void;
  type: AlertTypes | undefined;
  alertOpened: boolean;
  setAlertOpened: (value: boolean) => void;
}

export function ConnectionAlert({
  isOpen,
  onClose,
  type,
  alertOpened,
  setAlertOpened,
}: IConnectionAlertProps) {
  const [countdown, setCountdown] = useState<number | null>(null);

  useEffect(() => {
    if (countdown !== null && countdown > 0) {
      const interval = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    if (countdown === 0) {
      onClose();
    }
    return () => {
      return;
    };
  }, [countdown, onClose]);

  useEffect(() => {
    if (isOpen || alertOpened) {
      setAlertOpened(false);
      setCountdown(globalVars.doctor.alertTimeout);
    }
  }, [isOpen, alertOpened, setAlertOpened]);

  const alertText = () => {
    switch (type) {
      case AlertTypes.Authentication:
        return "Authentication failed. Your account has not been registered.";
      case AlertTypes.Connection:
      default:
        return "Server connection failed! Please check your internet connection.";
    }
  };

  return isOpen ? (
    <Alert status="error" position="absolute" top="5" left="5" width="auto">
      <AlertIcon />
      <Box display="flex" flexDirection="column" mx="2">
        <AlertTitle fontSize="lg">Oh no...</AlertTitle>
        <AlertDescription display="block">{alertText()}</AlertDescription>
      </Box>
      <CloseButton onClick={onClose} ml="3" />
    </Alert>
  ) : null;
}
