import {
  Box,
  Flex,
  HStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { OHIcon } from "@packages/theme/OHIcon";

import { ProfileInfoDropdown } from "components/generic/Profile/ProfileInfoDropdown";

export function TopNav() {
  const bg = useColorModeValue("white", "gray.800");
  const mobileNav = useDisclosure();

  return (
    <Box
      shadow="md"
      bg={bg}
      w="full"
      px={{ base: 2, sm: 4 }}
      py={1}
      zIndex={1000}
    >
      <Flex alignItems="center" justifyContent="space-between" mx="auto">
        <HStack>
          <OHIcon className="w-8 ml-1" />
        </HStack>
        <HStack
          spacing={3}
          display={mobileNav.isOpen ? "none" : "flex"}
          alignItems="center"
        >
          <ProfileInfoDropdown className="m-0" />
        </HStack>
      </Flex>
    </Box>
  );
}
