import { useAuth0 } from "@auth0/auth0-react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalVars from "@packages/config/globalVars";
import config from "@packages/config/spa";
import { useAllForms } from "api/hooks/schema";
import { useGenericModals } from "contexts/GenericModals";
import { useModalState } from "contexts/GlobalModalState";
import { formsAvailable } from "utils/medspecSchema";
interface IProfileInfoDropdownProps {
  className?: string;
}

export function ProfileInfoDropdown({ className }: IProfileInfoDropdownProps) {
  const genericModals = useGenericModals();
  const { data: forms } = useAllForms();
  const { user, logout } = useAuth0();
  const { startCreatingPatient } = useModalState();

  return (
    <div className={className}>
      <Flex>
        <ButtonGroup variant="outline" mr="2" size="sm" my="auto">
          <Button
            isDisabled={!formsAvailable(forms)}
            onClick={() => startCreatingPatient()}
            colorScheme="green"
            leftIcon={<AddIcon my="auto" w="3" />}
            aria-label="new-patient-button"
          >
            New Patient
          </Button>
        </ButtonGroup>
        <Menu>
          <MenuButton as={Button} my="auto" variant="ghost" size="lg">
            <Flex>
              <Avatar size="sm" name={user?.given_name} src={user?.picture} />
              <p className="my-auto ml-3">
                {user?.given_name} {user?.family_name}
              </p>
            </Flex>
          </MenuButton>
          <MenuList p="4">
            <div className="mb-2 text-center">
              <Avatar size="xl" name={user?.given_name} src={user?.picture} />
              <p className="mt-2 text-lg font-semibold">
                {user?.given_name} {user?.family_name}
              </p>
              <p className="mb-2 text-sm">Unknown</p>
              <p className="mb-2 text-xs">
                {config.version} - {`${config.commit}`.substring(0, 8)}
              </p>
              <ButtonGroup size="xs">
                <Button
                  onClick={genericModals.openPreferencesModal}
                  leftIcon={<FontAwesomeIcon icon={["fas", "gear"]} />}
                >
                  Preferences
                </Button>
                <Button
                  onClick={() => {
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    });
                  }}
                  leftIcon={
                    <FontAwesomeIcon icon={["fas", "right-from-bracket"]} />
                  }
                >
                  Log Out
                </Button>
              </ButtonGroup>
            </div>
            <MenuDivider />
            <MenuGroup title="Help" m="2">
              <MenuItem
                onClick={() => window.open(globalVars.doctor.userGuideLink)}
                style={{ textDecoration: "none" }}
              >
                Documentation
              </MenuItem>
              <MenuItem
                onClick={() =>
                  window.open(
                    "https://handstek.atlassian.net/servicedesk/customer/portals",
                  )
                }
                style={{ textDecoration: "none" }}
              >
                Contact Support
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
    </div>
  );
}
