import { Box, Center, Container, Spacer, Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/react";
import { OHIcon } from "@packages/theme/OHIcon";

const randomTips = [
  "Did you know that you can rearrange columns by clicking the Manage Columns button and dragging the columns?",
  "Delivery status icons are useful to determine if a patient has received the SMS, hover over an icon to see what it means.",
];

export function LoadingCard({
  description,
  tip = false,
  logo = false,
  className,
}: {
  description: string;
  tip?: boolean;
  logo?: boolean;
  className?: string;
}) {
  return (
    <Container m="auto" className={className}>
      <Box py="6" rounded="md" shadow="xl" m="auto">
        <Stack spacing="4" alignItems="center">
          {logo && (
            <>
              <OHIcon className="h-10 mx-auto" />
              <Spacer />
            </>
          )}
          <Spinner thickness="4px" size="lg" />
          <p
            className={
              "text-lg text-center font-semibold invert mix-blend-difference"
            }
          >
            {description}
          </p>
          {tip && (
            <>
              <Spacer />
              <p className="px-8 text-center">
                Tip: {randomTips[Math.floor(Math.random() * randomTips.length)]}
              </p>
            </>
          )}
        </Stack>
      </Box>
    </Container>
  );
}

export function LoadingCube() {
  return (
    <Center height="70%">
      <Spinner thickness="4px" size="lg" />
    </Center>
  );
}
