import {
  createPaymentIntent,
  fetchBalance,
  fetchPaymentConfig,
  fetchTransaction,
} from "@packages/api";
import { useAuthToken } from "@packages/auth";
import { PaymentConfig, PaymentIntent } from "@packages/types";
import { useMutation, useQuery } from "@tanstack/react-query";

import { useError } from "../../contexts/AlertContext";

export function usePaymentConfig() {
  const { token } = useAuthToken();
  return useQuery<PaymentConfig, undefined>(
    ["payment-config"],
    async () => await fetchPaymentConfig(token),
  );
}

export function useCreatePaymentIntent() {
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(
    async (paymentIntent: PaymentIntent) =>
      createPaymentIntent(token, paymentIntent),
    {
      onError: error,
    },
  );
}

export function useBalance(email: string) {
  const { token } = useAuthToken();
  return useQuery<{ formattedBalance: string; balance: number }, undefined>(
    ["payment-balance"],
    async () => await fetchBalance(token, email),
    {
      refetchOnWindowFocus: true,
    },
  );
}

export function useTransaction(email: string) {
  const { token } = useAuthToken();
  return useQuery(
    ["payment-transactions"],
    async () => await fetchTransaction(token, email),
  );
}
