import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useActiveForm } from "api/hooks/schema";
import { useModalState } from "contexts/GlobalModalState";
import { getIcon } from "utils/utils";

interface IExternalFieldProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect?: (accessor: string) => void;
  counters?: boolean;
}

interface IFieldSelectProps {
  slideID: string;
  fieldKey: number;
}

export function ExternalFieldModal({
  isOpen,
  onClose,
  onSelect,
  counters = false,
}: IExternalFieldProps) {
  const { editingFormID } = useModalState();
  if (!editingFormID) {
    return <div />;
  }
  const { data: form } = useActiveForm();
  const slides = form !== undefined ? Object.entries(form.slides) : [];

  const onFieldSelect = ({ slideID, fieldKey }: IFieldSelectProps) => {
    if (onSelect !== undefined) {
      const fields = slides.find(
        (slide) => slide.length > 0 && slide[0] === slideID,
      );
      if (fields && fields[1] && fields[1].fields) {
        const field = fields[1].fields[fieldKey];
        if (field) {
          onSelect(`${slideID}.${field.id}`);
        }
      }
    }
    onClose();
  };

  const onCounterSelect = (counter) => {
    if (onSelect !== undefined) {
      onSelect(`counter.${counter}`);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>External Fields</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Accordion allowMultiple>
            {slides.map(([slideID, slide], key) => (
              <AccordionItem key={key}>
                <AccordionButton>
                  <HStack className="grow">
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={["fas", "sticky-note"]}
                    />
                    <p className="font-semibold">{slide.name}</p>
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  {slide.fields !== undefined && slide.fields.length > 0 ? (
                    slide.fields?.map((field, key) => (
                      <Button
                        key={key}
                        w="full"
                        my="1"
                        onClick={() =>
                          onFieldSelect({
                            slideID,
                            fieldKey: key,
                          })
                        }
                      >
                        {getIcon(field.type)}
                        <p className="grow">
                          {field.name.length < 50
                            ? field.name
                            : `${field.name.substring(0, 50)}...`}
                        </p>
                      </Button>
                    ))
                  ) : (
                    <p className="my-5 text-center text-slate-400">
                      No fields available.
                    </p>
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
            {counters && (
              <AccordionItem>
                <AccordionButton>
                  <HStack className="grow">
                    <FontAwesomeIcon className="mr-1" icon={["fas", "flag"]} />
                    <p className="font-semibold">Counters</p>
                  </HStack>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  {form?.actions.counter !== undefined &&
                  form?.actions.counter.counters.length > 0 ? (
                    form?.actions.counter.counters.map((counter, key) => (
                      <Button
                        key={key}
                        w="full"
                        my="1"
                        onClick={() => onCounterSelect(counter)}
                      >
                        <p className="grow">
                          {counter.length < 50
                            ? counter
                            : `${counter.substring(0, 50)}...`}
                        </p>
                      </Button>
                    ))
                  ) : (
                    <p className="my-5 text-center text-slate-400">
                      No counters available.
                    </p>
                  )}
                </AccordionPanel>
              </AccordionItem>
            )}
          </Accordion>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
