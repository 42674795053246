import { apiClient, withAuth } from "../client";

export const getTranscriptionSummary = async ({
  token,
  prompt,
  deepgramRequestId,
  email,
}: {
  token: string;
  prompt: string;
  deepgramRequestId: string;
  email: string;
}): Promise<string> => {
  const { data } = await apiClient.post(
    "/transcription/summarise",
    { prompt, deepgramRequestId, email },
    withAuth(token),
  );
  return data;
};

export const getTranscriptionPrompt = async ({
  token,
  email,
}: {
  token: string;
  email: string;
}): Promise<string> => {
  const { data } = await apiClient.get(
    `/transcription/prompt/${email}`,
    withAuth(token),
  );
  return data;
};

export interface TranscriptionUsage {
  summary: {
    durationInSeconds: number;
    durationInWords: string;
    usageFeeAud: number;
  };
  usage: {
    email: string;
    requestId: string;
    usageFeeAud: number;
    type: string;
    createdAt: string;
  }[];
}
export const getTranscriptionUsage = async ({
  token,
  email,
}: {
  token: string;
  email: string;
}): Promise<TranscriptionUsage> => {
  const { data } = await apiClient.get(
    `/transcription/usage/${email}`,
    withAuth(token),
  );
  return data;
};
export const postTranscriptionPrompt = async ({
  token,
  email,
  prompt,
  title,
}: {
  token: string;
  email: string;
  prompt: string;
  title: string;
}): Promise<string> => {
  const { data } = await apiClient.post(
    "/transcription/prompt",
    {
      email,
      prompt,
      title,
    },
    withAuth(token),
  );
  return data;
};

export const postDeepgramTransaction = async ({
  token,
  email,
  deepgramDurationInSeconds,
  deepgramRequestId,
}: {
  token: string;
  email: string;
  deepgramDurationInSeconds: number;
  deepgramRequestId: string;
}): Promise<string> => {
  const { data } = await apiClient.post(
    "/transcription",
    {
      email,
      transactionType: "deepgram",
      deepgramDurationInSeconds,
      deepgramRequestId,
    },
    withAuth(token),
  );
  return data;
};
