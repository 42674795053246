import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/layout";
import {
  Collapse,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isFormValid } from "@packages/api";
import { hasTesterRole } from "@packages/auth";
import { useAllForms } from "api/hooks/schema";
import { useModalState } from "contexts/GlobalModalState";

import { InvalidTooltip } from "../generic/Tooltips/InvalidTooltip";

interface INavItemProps {
  icon?: IconName;
  iconColour?: string;
  invalidTooltip?: boolean;
  onClick: () => void;
  active: boolean;
  className?: string;
  children?: any;
}

interface ISideNavProps {
  isAdmin: boolean;
  className?: string;
}

const NavItem = ({
  icon,
  iconColour,
  invalidTooltip = false,
  onClick,
  active,
  className,
  children,
}: INavItemProps) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Flex
      align="center"
      px="4"
      pl="4"
      py="3"
      cursor="pointer"
      color="gray.900"
      bg={active ? "gray.200" : hover ? "gray.100" : ""}
      _dark={
        hover
          ? { color: "gray.900" }
          : active
            ? { color: "gray.900" }
            : { color: "gray.400" }
      }
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      role="group"
      fontWeight="semibold"
      transition=".15s ease"
      className={className}
    >
      {invalidTooltip ? (
        <InvalidTooltip />
      ) : (
        icon && (
          <Tooltip
            bg="red.500"
            label={invalidTooltip ? "Invalid Form" : null}
            placement="top"
          >
            <div>
              <FontAwesomeIcon
                className="w-4 mx-2"
                icon={["fas", icon]}
                color={iconColour ? iconColour : ""}
              />
            </div>
          </Tooltip>
        )
      )}
      {children}
    </Flex>
  );
};

const SidebarContent = ({ isAdmin, className }: ISideNavProps) => {
  const { data: forms } = useAllForms();
  const { activeFormID, setActiveFormID, editingFormID, editingFormTab } =
    useModalState();

  const navigate = useNavigate();
  const patientLists = useDisclosure();
  const messaging = useDisclosure();

  const isTester = hasTesterRole();

  return (
    <Box
      as="nav"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="white"
      _dark={{ bg: "gray.800" }}
      color="inherit"
      borderRightWidth="1px"
      display={{
        base: "none",
        md: "unset",
      }}
      className={className}
    >
      <Flex
        direction="column"
        as="nav"
        fontSize="sm"
        color="gray.600"
        aria-label="Main Navigation"
      >
        <NavItem
          icon={"house"}
          onClick={() => navigate("/")}
          active={window.location.hash === "#/"}
        >
          Dashboard
        </NavItem>
        <NavItem
          icon={"list"}
          onClick={() => patientLists.onToggle()}
          active={false}
        >
          Patient Lists
          <FontAwesomeIcon
            icon={["fas", "angle-right"]}
            size="xs"
            className={`ml-auto ${
              patientLists.isOpen ? "rotate-90" : ""
            } transition-all`}
          />
        </NavItem>
        <Collapse in={patientLists.isOpen}>
          {forms &&
            forms.map((form, key) => {
              const valid = isFormValid(form, forms);
              return (
                <NavItem
                  key={key}
                  icon={valid ? "book" : "exclamation-triangle"}
                  iconColour={valid ? "" : "red"}
                  invalidTooltip={!valid}
                  onClick={() => {
                    setActiveFormID(form.id);
                    navigate("/patients/list");
                  }}
                  active={
                    window.location.hash === "#/patients/list" &&
                    activeFormID === form.id
                  }
                  className="!pl-10 !pt-2 font-red-500"
                >
                  {form.name}
                </NavItem>
              );
            })}
        </Collapse>
        <NavItem
          icon={"message"}
          onClick={() => messaging.onToggle()}
          active={false}
        >
          Messaging
          <FontAwesomeIcon
            icon={["fas", "angle-right"]}
            size="xs"
            className={`ml-auto ${
              messaging.isOpen ? "rotate-90" : ""
            } transition-all`}
          />
        </NavItem>
        <Collapse in={messaging.isOpen}>
          <NavItem
            icon={"calendar"}
            onClick={() => navigate("/patients/scheduling")}
            active={window.location.hash === "#/patients/scheduling"}
            className="!pl-10 !pt-2"
          >
            SMS Scheduling
          </NavItem>
          <NavItem
            icon={"copy"}
            onClick={() => navigate("/patients/templates")}
            active={window.location.hash === "#/patients/templates"}
            className="!pl-10 !pt-2"
          >
            SMS Templates
          </NavItem>
        </Collapse>
        {isAdmin && (
          <NavItem
            icon={"book"}
            onClick={() => {
              if (editingFormID && editingFormTab) {
                navigate(`/admin/forms/${editingFormTab}`);
              } else {
                navigate("/admin/forms");
              }
            }}
            active={window.location.hash.includes("#/admin/forms")}
          >
            Form Management
          </NavItem>
        )}
        {isTester && (
          <NavItem
            icon={"microphone"}
            onClick={() => navigate("/patients/voice-transcription")}
            active={window.location.hash.includes(
              "#/patients/voice-transcription",
            )}
          >
            Transcription
          </NavItem>
        )}
        <NavItem
          icon="gear"
          onClick={() => navigate("/settings/billing")}
          active={window.location.hash === "/settings/billing"}
        >
          Settings
        </NavItem>
      </Flex>
    </Box>
  );
};

export function SideNav({ isAdmin }: ISideNavProps) {
  const sidebar = useDisclosure();

  return (
    <Box
      as="section"
      bg="gray.50"
      w="full"
      _dark={{ bg: "gray.700" }}
      minH="100vh"
      pt="3"
    >
      <SidebarContent isAdmin={isAdmin} />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent isAdmin={isAdmin} className="w-full border-r-0" />
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
