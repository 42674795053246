import {
  IAction,
  IFlowItem,
  IForm,
  IFormAlertConfig,
  IFormOut,
  IMedspecSlide,
  IMedspecSlideOut,
  IParameterGroup,
  IPostRule,
  IPostRuleOut,
} from "@packages/types";

import { apiClient, config, withAuth } from "../client";
import {
  serialiseAlertConfig,
  serialiseAllForms,
  serialiseFlowItem,
  serialiseForm,
  serialiseMedspecSlide,
  serialisePostCondition,
} from "../utils/serialisation";

export const fetchAllForms = async (token: string) => {
  const { data } = await apiClient.get(
    `/schema/${config.tenant}/all`,
    withAuth(token),
  );
  return serialiseAllForms(data);
};

export const fetchForm = async (formID: string): Promise<IForm> => {
  const { data } = await apiClient.get(
    `/schema/${config.tenant}/${formID}/medspec`,
  );
  return serialiseForm(formID, data);
};

export const registerForm = async (token: string, formID: string) => {
  const { data } = await apiClient.post(
    `/schema/${config.tenant}/${formID}/medspec`,
    {},
    withAuth(token),
  );
  return serialiseForm(formID, data);
};

export const deregisterForm = async (token: string, formID: string) => {
  await apiClient.delete(`/form/${formID}`, withAuth(token));
  return formID;
};

export const updateForm = async (
  token: string,
  formID: string,
  form: IFormOut,
) => {
  const { data } = await apiClient.put(
    `/schema/${config.tenant}/${formID}/medspec`,
    form,
    withAuth(token),
  );
  return serialiseForm(formID, data);
};

export const cloneForm = async (token: string, form: IForm) => {
  const { data } = await apiClient.post(
    `/schema/${config.tenant}/${form.id}/medspec/clone`,
    { ...form, template_id: form.templateID },
    withAuth(token),
  );

  return serialiseForm(data.id, data);
};

export const registerAction = async (
  token: string,
  formID: string,
  action: IAction,
): Promise<IParameterGroup> => {
  const { data } = await apiClient.post(
    `/schema/${config.tenant}/${formID}/medspec/actions`,
    action,
    withAuth(token),
  );
  return { ...data, id: formID };
};

export const deregisterAction = async (
  token: string,
  formID: string,
  action: string,
) => {
  await apiClient.delete(
    `/schema/${config.tenant}/${formID}/medspec/actions/${action}`,
    withAuth(token),
  );
};

// Flow steps
export const createFlowStep = async (
  token: string,
  formID: string,
  flow: IFlowItem,
): Promise<IFlowItem> => {
  const { data } = await apiClient.post(
    `/schema/${config.tenant}/${formID}/medspec/flow`,
    flow,
    withAuth(token),
  );
  return serialiseFlowItem(data);
};

export const updateFlowStep = async (
  token: string,
  formID: string,
  flow: IFlowItem,
): Promise<IFlowItem> => {
  const { data } = await apiClient.put(
    `/schema/${config.tenant}/${formID}/medspec/flow`,
    flow,
    withAuth(token),
  );
  return serialiseFlowItem(data);
};

export const deleteFlowStep = async (
  token: string,
  formID: string,
  src: string,
) => {
  await apiClient.delete(
    `/schema/${config.tenant}/${formID}/medspec/flow/${src}`,
    withAuth(token),
  );
  return src;
};

// slides
export const createSlide = async (
  token: string,
  formID: string,
  slide: IMedspecSlideOut,
): Promise<IMedspecSlide> => {
  const { data } = await apiClient.post(
    `/schema/${config.tenant}/${formID}/medspec/slides`,
    slide,
    withAuth(token),
  );
  return serialiseMedspecSlide(data);
};

export const updateSlide = async (
  token: string,
  formID: string,
  slide: IMedspecSlideOut,
): Promise<IMedspecSlide> => {
  const { data } = await apiClient.put(
    `/schema/${config.tenant}/${formID}/medspec/slides`,
    slide,
    withAuth(token),
  );
  return serialiseMedspecSlide(data);
};

export const deleteSlide = async (
  token: string,
  formID: string,
  slide: string,
) => {
  await apiClient.delete(
    `/schema/${config.tenant}/${formID}/medspec/slides/${slide}`,
    withAuth(token),
  );
  return slide;
};

// postconditions
export const createPostCondition = async (
  token: string,
  formID: string,
  condition: IPostRuleOut,
): Promise<IPostRule> => {
  const { data } = await apiClient.post(
    `/schema/${config.tenant}/${formID}/medspec/postconditions`,
    condition,
    withAuth(token),
  );
  return serialisePostCondition(data);
};

export const updatePostCondition = async (
  token: string,
  formID: string,
  condition: IPostRuleOut,
): Promise<IPostRule> => {
  const { data } = await apiClient.put(
    `/schema/${config.tenant}/${formID}/medspec/postconditions`,
    condition,
    withAuth(token),
  );
  return serialisePostCondition(data);
};

export const deletePostCondition = async (
  token: string,
  formID: string,
  condition: string,
) => {
  await apiClient.delete(
    `/schema/${config.tenant}/${formID}/medspec/postconditions/${condition}`,
    withAuth(token),
  );
  return condition;
};

// alert config
export const updateAlertConfig = async (
  token: string,
  formID: string,
  alertConfig: IFormAlertConfig,
) => {
  const { data } = await apiClient.post(
    `/schema/${config.tenant}/${formID}/medspec/alertconfig`,
    alertConfig,
    withAuth(token),
  );
  return serialiseAlertConfig(data);
};
