import { Box, Tooltip } from "@chakra-ui/react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IHelpTooltipProps {
  description: string;
  className?: string;
  size?: SizeProp;
  isAlert?: boolean;
}

export function HelpTooltip({
  description,
  className,
  size,
  isAlert = false,
}: IHelpTooltipProps) {
  return (
    <Tooltip label={description} bg={isAlert ? "red" : ""}>
      <Box display="inline-block">
        {isAlert ? (
          <FontAwesomeIcon
            className={className}
            color="red"
            size={size}
            icon={["fas", "circle-exclamation"]}
          />
        ) : (
          <FontAwesomeIcon
            className={className}
            size={size}
            icon={["fas", "circle-question"]}
          />
        )}
      </Box>
    </Tooltip>
  );
}
