import { FieldTypes } from "@packages/types";
import { IDeliveryStatus, SMSStatusFlag } from "@packages/types";
import dateFormat from "dateformat";

export function camelCaseKeysToSnake(obj) {
  if (typeof obj !== "object") return obj;
  const newObj = { ...obj };
  for (const oldName in newObj) {
    // Camel to underscore
    const newName = oldName.replace(/([A-Z])/g, function ($1) {
      return "_" + $1.toLowerCase();
    });
    // Only process if names are different
    if (newName !== oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      // eslint-disable-next-line no-prototype-builtins
      if (newObj.hasOwnProperty(oldName)) {
        newObj[newName] = newObj[oldName];
        delete newObj[oldName];
      }
    }
    // Recursion
    if (typeof newObj[newName] === "object") {
      newObj[newName] = camelCaseKeysToSnake(newObj[newName]);
    }
  }
  return newObj;
}

// Function for camelcasing our name (to use as a key)
export function camalize(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

// Returns all key value pairs of an object
export function getEntries(data) {
  let r = {};
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (!Array.isArray(value)) {
      if (r[key] === undefined) {
        r[key] = value;
      }
    } else {
      r = { ...r, ...getEntries(value) };
    }
  });
  return r;
}

// Reorders a list based on the start and end index
export function reorder(list, startIndex, endIndex): any[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

// Returns a field value with correct formatting
export function renderFieldType(value: any, type: FieldTypes | undefined) {
  if (value) {
    if (value.toString().length > 0) {
      switch (type) {
        case FieldTypes.MultiSelect:
          return value.join(", ");
        case FieldTypes.DateTime:
          return dateFormat(value, "dd/mm/yyyy");
        default:
          return value;
      }
    } else {
      return "N/A";
    }
  } else {
    return null;
  }
}

// Determines SMS delivery status for the given phone number and dispatch time.
export function calcDeliveryStatus(
  records: IDeliveryStatus[],
  dateCreatedOn: Date,
): SMSStatusFlag {
  // Get all deliveries associated with the phone number
  if (records.length > 0) {
    // Use some logic to determine the correct delivery status record
    const closestCandidate = records.sort((a, b) => {
      const distancea = Math.abs(dateCreatedOn.getTime() - a.queueTS.getTime());
      const distanceb = Math.abs(dateCreatedOn.getTime() - b.queueTS.getTime());
      return distancea - distanceb;
    })[0];
    // Determine timing diff, and if more than 30sec, ignore
    const delta =
      Math.abs(dateCreatedOn.getTime() - closestCandidate.queueTS.getTime()) /
      1000;
    if (closestCandidate && delta < 30) {
      // Determine SMS status based on recipient's Status
      if (closestCandidate.sentTS) {
        if (closestCandidate.deliveredTS) {
          return SMSStatusFlag.Delivered;
        } else {
          return SMSStatusFlag.Sent;
        }
      } else {
        return SMSStatusFlag.Queued;
      }
    } else {
      return SMSStatusFlag.Queued;
    }
  } else {
    return SMSStatusFlag.Unknown;
  }
}
