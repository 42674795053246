/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export enum ConfirmTypes {
  Delete,
  RemoveFlowSlide,
  Remove,
  DeleteAll,
  Discard,
  FormChange,
  InvalidObject,
  ColumnReset,
}

export enum AdditionalButtons {
  CloneForm = "cloneButton",
}

export interface IAdditionalButton {
  name: AdditionalButtons;
  onClick: () => void;
}

interface IAdditionalButtonProps {
  key: number;
  onClick: () => void;
}

interface IConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  asyncFunc: any;
  asyncProps: any;
  then: (() => void) | undefined;
  type: ConfirmTypes | undefined;
  buttons?: IAdditionalButton[];
}

export function ConfirmModal({
  isOpen,
  onClose,
  then,
  asyncFunc,
  asyncProps,
  type,
  buttons,
}: IConfirmModalProps) {
  const modalText = () => {
    switch (type) {
      case ConfirmTypes.FormChange:
        return (
          <>
            <p>Are you sure you want to modify the form structure?</p>
            <p className="text-red-600">
              <strong>Warning: All patient records will be deleted</strong>
            </p>
          </>
        );
      case ConfirmTypes.InvalidObject:
        return (
          <>
            <p className="text-red-600">
              <strong>Warning: One or more values are invalid</strong>
            </p>
            <p>Would you like to discard your changes?</p>
          </>
        );
      case ConfirmTypes.ColumnReset:
        return <p>Are you sure you want to reset your column layout?</p>;
      case ConfirmTypes.Discard:
        return <p>Are you sure you want to discard your changes?</p>;
      case ConfirmTypes.DeleteAll:
        return <p>Are you sure you want to delete all items?</p>;
      case ConfirmTypes.RemoveFlowSlide:
        return (
          <>
            <p>Are you sure you want to remove this slide?</p>
            <p className="text-red-600">
              <strong>Warning: All related connections will be deleted</strong>
            </p>
          </>
        );
      case ConfirmTypes.Remove:
        return <p>Are you sure you want to remove this item?</p>;
      case ConfirmTypes.Delete:
      default:
        return <p>Are you sure you want to delete this item?</p>;
    }
  };

  const modalButtonText = () => {
    switch (type) {
      case ConfirmTypes.ColumnReset:
        return "Reset";
      case ConfirmTypes.FormChange:
        return "Modify";
      case ConfirmTypes.InvalidObject:
        return "Discard Changes";
      case ConfirmTypes.Discard:
        return "Discard";
      case ConfirmTypes.RemoveFlowSlide:
      case ConfirmTypes.Remove:
        return "Remove";
      case ConfirmTypes.DeleteAll:
      case ConfirmTypes.Delete:
      default:
        return "Delete";
    }
  };

  const CloneButton = ({ onClick }: IAdditionalButtonProps) => (
    <>
      <Button
        colorScheme="brand"
        onClick={() => {
          onClick();
          onClose();
        }}
      >
        Clone & Edit
      </Button>
    </>
  );

  const ButtonFunctions = {
    [AdditionalButtons.CloneForm]: CloneButton,
  };

  const [clickQueued, setClickQueued] = useState<boolean>(false);

  const onClickClose = () => {
    setClickQueued(false);
    if (then) {
      then();
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalText()}</ModalBody>
          <ModalFooter>
            {buttons &&
              buttons.map((button, key) => {
                const Component = ButtonFunctions[button.name];
                return <Component key={key} onClick={button.onClick} />;
              })}
            <Button
              onClick={() => {
                if (asyncFunc !== undefined && asyncProps !== undefined) {
                  setClickQueued(true);
                  asyncFunc(asyncProps).then(onClickClose);
                } else {
                  onClickClose();
                }
              }}
              isLoading={clickQueued}
              isDisabled={false}
              colorScheme={"red"}
              className="ml-3"
            >
              {modalButtonText()}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
