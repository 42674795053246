import { createContext, ReactNode, useContext, useState } from "react";

// Column contexts
interface IAuthContext {
  token: string;
  setToken: (token: string) => void;
}

export const AuthContext = createContext<IAuthContext>({
  token: "",
  setToken: () => {
    return;
  },
});

export function useAuthToken(): IAuthContext {
  const ctx = useContext(AuthContext);
  if (ctx === undefined) {
    throw new Error("useColumns must be used within a AuthContextProvider");
  }
  return ctx as IAuthContext;
}

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState("");

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
}
