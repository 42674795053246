import { fetchAllUsers, fetchColumns, updateColumns } from "@packages/api";
import { useAuthToken, useRole } from "@packages/auth";
import { IColumns, IDoctor } from "@packages/types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useError } from "contexts/AlertContext";

import { isRoleAdmin } from "../../utils/roles";

export function useUsers() {
  const roles = useRole();
  const { token } = useAuthToken();
  const { error } = useError();
  return useQuery<IDoctor[], undefined>(
    ["doctors"],
    async () => (isRoleAdmin(roles) ? await fetchAllUsers(token) : []),
    { onError: error },
  );
}

export function useStoredColumns() {
  const { token } = useAuthToken();
  const { error } = useError();
  return useQuery<IColumns, undefined>(
    ["columns"],
    async () => await fetchColumns(token),
    { onError: error },
  );
}

export function useUpdateStoredColumns() {
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(
    async (columns: IColumns) => updateColumns(token, columns),
    { onError: error },
  );
}
