import { ICreatingForm, IFlowItem, IFlowRule, IForm } from "@packages/types";

export function isFormDetailsValid(
  form?: IForm | ICreatingForm,
  forms?: IForm[],
) {
  if (form === undefined) {
    return false;
  }
  if (forms === undefined) {
    return false;
  }
  if (form.name === undefined) {
    return false;
  }
  if (form.name.length < 1) {
    return false;
  }
  if (forms) {
    if (form["id"]) {
      if (forms?.some((f) => f.id !== form["id"] && f.name === form.name)) {
        return false;
      }
    } else {
      if (forms?.some((f) => f.name === form.name)) {
        return false;
      }
    }
  }
  if (form.description === undefined) {
    return false;
  }
  if (form.description.length < 1) {
    return false;
  }
  if (form.endMessage === undefined) {
    return false;
  }
  return form.endMessage.length >= 1;
}

export function isFormFlowValid(form?: IForm) {
  if (form === undefined) {
    return false;
  }
  if (form.flow === undefined) {
    return false;
  }
  if (form.flow.some((f) => !isFlowItemValid(f))) {
    return false;
  }
  return !form.flow.some((f) => !isFlowItemValid(f));
}

export function isFlowItemValid(flow?: IFlowItem) {
  if (flow === undefined) {
    return false;
  }
  if (flow.defaultDest === undefined || flow.defaultDest === "unselected") {
    return false;
  }
  if (flow.rules === undefined) {
    return false;
  }
  return flow.rules.every((rule) => isFlowRuleValid(rule));
}

export function isFlowRuleValid(rule?: IFlowRule) {
  if (rule === undefined) {
    return false;
  }
  if (rule.id === undefined) {
    return false;
  }
  if (rule.conditions === undefined) {
    return false;
  }
  if (rule.conditions.length < 1) {
    return false;
  }
  if (
    !rule.conditions.every(
      (con) =>
        con.field !== undefined &&
        con.field.length > 0 &&
        con.operator !== undefined &&
        con.operator.length > 0 &&
        con.argument !== undefined,
    )
  ) {
    return false;
  }
  return rule.then.every(
    (action) => action.action !== undefined && action.arguments !== undefined,
  );
}
