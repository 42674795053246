// validate Australian mobile phone number
export const validatePhoneNumber = (phoneNumber: string) => {
  const regex = /^[0]4\d{8}$/;
  return regex.test(phoneNumber.replace(/ /g, ""));
};

type ValidateInputPhoneNumbers = {
  invalidPhoneNumbers: string[];
  isValid: boolean;
};

export const validateInputPhoneNumbers = (
  phoneNumbers: string,
): ValidateInputPhoneNumbers => {
  const phoneNumbersArray = phoneNumbers.split("\n").map((item) => item.trim());
  const whitelistedNumbers = [import.meta.env.VITE_TEST_SMS_NUMBER || ""];
  const invalidNumbers = {
    invalidPhoneNumbers: phoneNumbersArray.filter(
      (phoneNumber) =>
        !whitelistedNumbers.includes(phoneNumber) &&
        phoneNumber.trim().length > 0 &&
        !validatePhoneNumber(phoneNumber),
    ),
  };
  return {
    isValid: invalidNumbers.invalidPhoneNumbers.length === 0,
    ...invalidNumbers,
  };
};

// show validation errors
export const showValidationErrors = (
  phoneNumbers: string,
  validationResult: ValidateInputPhoneNumbers,
) => {
  const { invalidPhoneNumbers } = validationResult;
  let withErrors = phoneNumbers;
  invalidPhoneNumbers.forEach((phone) => {
    withErrors = withErrors.replace(
      phone,
      `${phone} [Invalid. Must begin with 04 and be 10 digits]`,
    );
  });
  return withErrors;
};
