import { Tooltip } from "@chakra-ui/react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IInvalidTooltipProps {
  size?: SizeProp;
  className?: string;
}

export function InvalidTooltip({ size, className }: IInvalidTooltipProps) {
  return (
    <Tooltip bg="red.500" label={"Invalid Form"} placement="top">
      <div>
        <FontAwesomeIcon
          className={className ?? "mx-2 w-4"}
          icon={["fas", "exclamation-triangle"]}
          size={size}
          color={"red"}
        />
      </div>
    </Tooltip>
  );
}
