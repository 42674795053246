import { useState } from "react";
import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Payment from "../../../components/payment/Payment";

import { CustomAmount } from "./CustomAmount";

export const Checkout = () => {
  const amounts = [20, 50, 100, 500];

  const [customAmount, setCustomAmount] = useState("0");
  const [selectedAmount, setSelectedAmount] = useState(amounts[0]);
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  return (
    <Stack spacing="10" pt="10px">
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: "5", md: "6" }}>
        <Stack gap={{ base: "5", md: "6" }}>
          <h2>Pick your amount:</h2>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: "5", md: "6" }}>
            {amounts.map((amount) => (
              <Button
                key={amount}
                boxShadow={
                  !isCustomAmount && selectedAmount === amount ? "xl" : "none"
                }
                padding="50px"
                colorScheme={
                  !isCustomAmount && selectedAmount === amount
                    ? "teal"
                    : "bg.surface"
                }
                size="lg"
                variant={
                  !isCustomAmount && selectedAmount === amount
                    ? "solid"
                    : "outline"
                }
                onClick={() => {
                  setSelectedAmount(amount);
                  setIsCustomAmount(false);
                  setCustomAmount("0");
                }}
              >
                {selectedAmount === amount && !isCustomAmount && (
                  <FontAwesomeIcon
                    icon={["fas", "check"]}
                    style={{
                      position: "absolute",
                      top: "0.25rem",
                      left: "0.25rem",
                    }}
                  />
                )}
                <Heading size={{ base: "sm", md: "md" }}>${amount}</Heading>
              </Button>
            ))}
          </SimpleGrid>
          <CustomAmount
            isCustomAmount={isCustomAmount}
            customAmount={customAmount}
            setCustomAmount={setCustomAmount}
            setIsCustomAmount={setIsCustomAmount}
            setSelectedAmount={setSelectedAmount}
          />
          <Stack gap={{ base: "5", md: "6" }}>
            <Box
              px={{ base: "4", md: "6" }}
              py={{ base: "5", md: "6" }}
              bg="bg.surface"
              borderRadius="lg"
              boxShadow="sm"
            >
              <Stack spacing={4} direction="column" align="start">
                <Text textStyle="sm" color="rgba(58.82%,63.53%,100%,1)">
                  <FontAwesomeIcon icon={["fas", "lock"]} /> Secure payment by
                  Stripe
                </Text>
                <Payment amount={selectedAmount} currency={"aud"} />
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </SimpleGrid>
    </Stack>
  );
};
