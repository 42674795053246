import { HStack } from "@chakra-ui/react";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IKeyValueFieldProps {
  keyName: string;
  value: string;
  icon?: [IconPrefix, IconName];
}

export function KeyValueField({ keyName, value, icon }: IKeyValueFieldProps) {
  return (
    <HStack p="1">
      {icon ? <FontAwesomeIcon icon={icon} className="my-auto" /> : null}
      <p className="font-semibold">{keyName}:</p>
      <p>{value}</p>
    </HStack>
  );
}
