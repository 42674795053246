import { ReactNode } from "react";
import { Button, ButtonGroup, Tooltip } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserRoles } from "@packages/auth";
import {
  FieldTypes,
  IColumn,
  IDoctor,
  IPatient,
  IPhoneDeliveryStatus,
  SMSStatusFlag,
} from "@packages/types";
import dateFormat from "dateformat";
import { calcDeliveryStatus, renderFieldType } from "misc";
import { getRowIconTooltipAndColour } from "utils/patientList";

import { validatePhoneNumber } from "../../../utils/phoneNumberValidation";

interface IColumnActionsProps {
  onDelete: (index) => void;
  onExpand: (index) => void;
}

interface ICellRow {
  index: number;
  original: IPatient;
}

interface ICell {
  value: any;
  row: ICellRow;
}

interface IColumnWithAccessor extends IColumn {
  accessor?: keyof IPatient | string;
  sticky?: "left" | "right";
  Cell: (cell: ICell) => ReactNode;
  sortType?: (a: ICellRow, b: ICellRow) => 0 | 1 | -1;
}

// Determines max cell width using length of string
// https://github.com/TanStack/react-table/issues/94#issuecomment-281167538
function calculateColumnWidth(
  accessor: string | undefined,
  headerText: string,
  type: FieldTypes | undefined,
  data: any,
): number {
  let max = 0;
  const maxWidth = 400;
  const magicSpacing = 14;
  if (accessor) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].response.length > 0) {
        if (
          data[i] !== undefined &&
          data[i].response[0].slides[accessor] !== null
        ) {
          const rendered = renderFieldType(
            data[i].response[0].slides[accessor] || "N/A",
            type,
          );
          if (rendered.length > max) {
            max = rendered.length;
          }
        }
      }
    }
  }
  return Math.min(
    maxWidth,
    Math.max(max, headerText.length + 3) * magicSpacing,
  );
}

const determineDateSortDirection =
  (accessor: string) =>
  (a: ICellRow, b: ICellRow): 0 | -1 | 1 => {
    const timeOfA = new Date(a.original[accessor]).getTime();
    const timeOfB = new Date(b.original[accessor]).getTime();
    if (timeOfA === timeOfB) {
      return 0;
    }
    return timeOfA > timeOfB ? 1 : -1;
  };

export function getDefaultColumns(roles?: UserRoles[]): IColumn[] {
  const defaultColumns: IColumn[] = [
    {
      Header: "Appointment Time",
      name: "Appointment Time",
      id: "appointmentTime",
      accessor: "appointmentTime",
      type: FieldTypes.DateTime,
      width: 220,
    },
    {
      Header: "Response Received Date",
      name: "Response Received Date",
      id: "responseReceivedDate",
      accessor: "response[0].createdAt",
      required: true,
      type: FieldTypes.DateTime,
      width: 230,
    },
    {
      Header: "Form Sent Date",
      name: "Form Sent Date",
      id: "formSentDate",
      accessor: "createdAt",
      required: true,
      type: FieldTypes.DateTime,
      width: 220,
    },
    {
      Header: "Phone Number",
      name: "Phone Number",
      accessor: "phone",
      required: true,
      disableSortBy: true,
      width: 160,
    },
  ];

  if (
    roles &&
    (roles.includes(UserRoles.Manager) || roles.includes(UserRoles.OfficeAdmin))
  ) {
    defaultColumns.unshift({
      Header: "Doctor",
      name: "Doctor",
      accessor: "doctor",
      width: 220,
    });
  }
  return defaultColumns;
}
export function renderAllPatientColumns(
  statuses: IPhoneDeliveryStatus | undefined,
  columns: IColumn[],
  data,
  users: IDoctor[],
  { onDelete, onExpand }: IColumnActionsProps,
): IColumnWithAccessor[] {
  return [
    // Add the Status column first
    {
      Header: "Status",
      fieldName: "status",
      required: true,
      sticky: "left",
      disableSortBy: true,
      width: 100,
      Cell: (cell) => {
        const status = statuses ? statuses[cell.row.original.phone] : undefined;

        // Get the row icon and tooltip
        const { icon, tooltip } = getRowIconTooltipAndColour(
          cell.row.original,
          !validatePhoneNumber(cell.row.original.phone)
            ? SMSStatusFlag.Invalid
            : calcDeliveryStatus(status ?? [], cell.row.original.createdAt),
        );

        // Render out the tooltip
        return (
          <Tooltip label={tooltip}>
            <div
              className="flex"
              data-testid={`${cell.row.original.phone}-status`}
            >
              <FontAwesomeIcon
                icon={icon}
                color="black"
                className="m-auto text-2xl"
              />
            </div>
          </Tooltip>
        );
      },
    },
    // Add other columns in-between
    ...columns.map((column) => {
      // If column width is defined, use defined width. Otherwise, calculate width.
      const width = column.width
        ? column.width
        : calculateColumnWidth(
            column.fieldName,
            column.Header,
            column.type,
            data,
          );

      const rendered: IColumnWithAccessor = {
        ...column,
        width: width,
        Cell: (cell) => {
          const doctor = users.find(
            (doctor) => cell.row.original.doctor === doctor.email,
          );
          switch (column.name) {
            case "Appointment Time":
              // Check if appointment time is actually defined
              if (!cell.row.original.appointmentTime) {
                return <p className="text-slate-300">None provided</p>;
              }
              return dateFormat(
                cell.row.original.appointmentTime,
                "hh:MM TT, dd/mm/yyyy",
              );
            case "Form Sent Date":
              // Apply unique formatting
              return dateFormat(
                cell.row.original.createdAt,
                "hh:MM TT, dd/mm/yyyy",
              );
            case "Response Received Date":
              // Check if response is actually defined
              if (
                cell.row.original.response === undefined ||
                cell.row.original.response.length < 1
              ) {
                return <p className="font-semibold text-red-500">Unreceived</p>;
              }
              // Apply unique formatting
              return dateFormat(
                cell.row.original.response[0].createdAt,
                "hh:MM TT, dd/mm/yyyy",
              );
            case "Phone Number":
              return renderFieldType(cell.value, column.type);
            case "Doctor":
              return doctor !== undefined
                ? `${doctor.firstName} ${doctor.lastName}`
                : cell.row.original.doctor ?? "Unknown";
            default:
              if (
                cell.row.original.response &&
                cell.row.original.response.length > 0 &&
                Object.entries(cell.row.original.response[0].slides).length > 0
              ) {
                return renderFieldType(cell.value, column.type);
              }
              return <p className="text-slate-300">N/A</p>;
          }
        },
      };
      if (column.type === "datetime" || column.name === "Appointment Time") {
        rendered.sortType = determineDateSortDirection(
          column.accessor || "createdAt",
        );
      }

      return rendered;
    }),
    // Add the Actions column first
    {
      Header: "Actions",
      fieldName: "actions",
      disableSortBy: true,
      required: true,
      sticky: "right",
      width: 140,
      Cell: (cell) => {
        return (
          <ButtonGroup>
            <Button
              onClick={() => onExpand(cell.row.index)}
              variant="solid"
              colorScheme="brand"
              isDisabled={
                !(
                  cell.row.original.response !== undefined &&
                  cell.row.original.response.length > 0
                )
              }
            >
              <FontAwesomeIcon icon={["fas", "arrow-up-right-from-square"]} />
            </Button>
            <Button
              onClick={() => onDelete(cell.row.index)}
              colorScheme="red"
              variant="solid"
            >
              <FontAwesomeIcon icon={["fas", "trash"]} className="mx-auto" />
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];
}
