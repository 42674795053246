import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stat,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFirstValidForm } from "@packages/api";
import { useAuthentication, useRole } from "@packages/auth";
import config from "@packages/config/spa";
import { emptyPatient, IPatientCreate } from "@packages/types";
import { useAddPatient } from "api/hooks/doctor";
import { useAllForms } from "api/hooks/schema";
import { useUsers } from "api/hooks/user";
import { useGenericModals } from "contexts/GenericModals";
import { useModalState } from "contexts/GlobalModalState";
import {
  showValidationErrors,
  validateInputPhoneNumbers,
} from "utils/phoneNumberValidation";

import { FormSelect } from "components/admin/ManageForms/FormSelect";
import { RemoveAction } from "components/generic/actions/Actions";
import { ConfirmTypes } from "components/generic/ConfirmModal";

import { isRoleAdmin } from "../../utils/roles";

import { SMSTemplateSelect } from "./SMSTemplates/SMSTemplateSelect";

export function CreatePatientModalWrapper() {
  // Function to prevent modal rendering if user not logged in
  const { user } = useAuthentication();
  if (!user) {
    return null;
  } else {
    return <CreatePatientModal />;
  }
}
const DEFAULT_PHONE_TEXT_AREA_HEIGHT = 100;
export function CreatePatientModal() {
  const genericModals = useGenericModals();
  const { user } = useAuthentication();
  const { data: forms } = useAllForms();

  const { creatingPatient, setCreatingPatient } = useModalState();
  const [creationQueued, setCreationQueued] = useState<boolean>(false);

  const [phoneTextAreaHeight, setPhoneTextAreaHeight] = useState<number>(
    DEFAULT_PHONE_TEXT_AREA_HEIGHT,
  );
  const {
    handleSubmit,
    reset,
    control,
    setError,
    setValue,
    clearErrors,
    trigger,
    formState: { isDirty, isValid, errors },
  } = useForm<IPatientCreate>({
    defaultValues: emptyPatient(),
  });

  const {
    fields: alertTargets,
    append: addAlertTarget,
    remove: removeAlertTarget,
  } = useFieldArray<any>({ name: "alertTargets", control });

  const roles = useRole();
  const isAdminUser = isRoleAdmin(roles);
  const { data: doctors } = useUsers();

  // create a variable to store the user that we want to store the new patient under
  const [doctorEmail, setDoctorEmail] = useState<string>(user?.email || "");
  const { mutate: createPatient, isLoading: isCreateLoading } =
    useAddPatient(doctorEmail);

  const onCreate = (data: IPatientCreate) => {
    setCreationQueued(true);
    data.phone
      .split("\n")
      .filter((phone) => phone.length > 0)
      .map((phone) =>
        createPatient({
          ...data,
          phone: phone,
          tenant: config.tenant,
        } as IPatientCreate),
      );
  };

  const onClose = () => {
    if (isDirty) {
      genericModals.openConfirmModal({
        then: () => {
          setCreatingPatient(false);
          reset();
        },
        confirmType: ConfirmTypes.Discard,
      });
    } else {
      setCreatingPatient(false);
      reset();
    }
  };

  useEffect(() => {
    if (creatingPatient === true) {
      const form = getFirstValidForm(forms);
      reset(emptyPatient(form?.id));
      if (form?.templateID) {
        setValue("templateID", form.templateID);
      }
    }
  }, [creatingPatient, forms, reset, setValue]);

  useEffect(() => {
    if (creationQueued && !isCreateLoading) {
      setCreationQueued(false);
      setCreatingPatient(false);
      reset();
    }
  }, [creationQueued, isCreateLoading, reset, setCreatingPatient]);

  return (
    <Modal
      isOpen={creatingPatient === true}
      onClose={onClose}
      autoFocus={false}
    >
      {creatingPatient !== true || doctors === undefined ? null : (
        <>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>New Patient</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
                {!isAdminUser ? null : (
                  <FormControl isRequired>
                    <FormLabel>Doctor</FormLabel>
                    <Select
                      value={doctorEmail}
                      onChange={(e) => setDoctorEmail(e.target.value)}
                    >
                      {doctors.map((doc, key) => (
                        <option key={key} value={doc.email}>
                          {doc.firstName} {doc.lastName}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>
                      Assign the patient to a specific doctor
                    </FormHelperText>
                  </FormControl>
                )}
                <FormControl isInvalid={errors.phone !== undefined} isRequired>
                  <FormSelect
                    control={control}
                    setValue={setValue}
                    forms={forms || []}
                  />
                </FormControl>
                <FormControl>
                  <SMSTemplateSelect control={control} />
                </FormControl>
                <FormControl>
                  <FormLabel>Appointment Time</FormLabel>
                  <Controller
                    name="appointmentTime"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        as={DatePicker}
                        selected={value}
                        onChange={(e) =>
                          onChange(
                            e === null ? undefined : (e as unknown as Date),
                          )
                        }
                        dateFormat="dd/MM/yyyy hh:mm a"
                        showTimeSelect
                      />
                    )}
                  />
                  <FormHelperText>
                    The time of the appointment (optional).
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <FormLabel>Phone Number(s)</FormLabel>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Phone number is required.",
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Textarea
                        aria-label="Phone Number(s)"
                        value={value === undefined ? "" : value}
                        height={phoneTextAreaHeight}
                        onFocus={(e) => {
                          // remove everything between []
                          onChange(
                            e.target.value.replace(/\[.*?]/g, "").trim(),
                          );
                        }}
                        onBlur={(e) => {
                          const text = e.target.value;
                          const validationResult =
                            validateInputPhoneNumbers(text);
                          if (!validationResult.isValid) {
                            setError(
                              "phone",
                              {
                                type: "focus",
                                message: "Invalid phone numbers",
                              },
                              {
                                shouldFocus: true,
                              },
                            );
                          } else {
                            clearErrors("phone");
                            trigger("phone");
                          }

                          onChange(
                            showValidationErrors(text, validationResult),
                          );
                        }}
                        onChange={(e) => {
                          onChange(e.target.value);

                          const lineHeight =
                            25 * e.target.value.split("\n").length;

                          setPhoneTextAreaHeight(
                            lineHeight < DEFAULT_PHONE_TEXT_AREA_HEIGHT
                              ? DEFAULT_PHONE_TEXT_AREA_HEIGHT
                              : lineHeight,
                          );
                        }}
                        name="phone"
                        placeholder="e.g. 0435431235"
                      />
                    )}
                  />
                  {errors.phone ? (
                    <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
                  ) : (
                    <FormHelperText>
                      The mobile number of the recipient(s).
                    </FormHelperText>
                  )}
                </FormControl>
                {doctors && (
                  <VStack>
                    <FormControl className="flex justify-between p-1 mb-2 border-b-2">
                      <FormLabel>Notification Recipients</FormLabel>
                      <div>
                        <Button
                          variant="outline"
                          colorScheme="green"
                          size="sm"
                          my="auto"
                          mx="2"
                          leftIcon={
                            <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                          }
                          onClick={() => addAlertTarget("")}
                        >
                          Add New
                        </Button>
                      </div>
                    </FormControl>
                    <FormControl>
                      {alertTargets.length > 0 ? (
                        alertTargets.map((target, key) => (
                          <Controller
                            key={target.id}
                            name={`alertTargets.${key}`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Box
                                border="1px"
                                p="4"
                                mx="2"
                                w="98%"
                                rounded="md"
                                borderColor="gray.300"
                              >
                                <HStack>
                                  <Stat size="sm">
                                    <Select
                                      value={
                                        doctors.find(
                                          (d) => d.email === value?.email,
                                        )?.email
                                      }
                                      onChange={(e) =>
                                        onChange(
                                          doctors.find(
                                            (d) => e.target.value === d.email,
                                          ),
                                        )
                                      }
                                    >
                                      <option key={0} value="">
                                        Unselected
                                      </option>
                                      {doctors.map((doctor, key) => {
                                        if (doctor.phone) {
                                          return (
                                            <option
                                              key={key++}
                                              value={doctor.email}
                                            >
                                              {doctor.firstName}{" "}
                                              {doctor.lastName}
                                            </option>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </Select>
                                  </Stat>
                                  <RemoveAction
                                    onRemove={() => removeAlertTarget(key)}
                                  />
                                </HStack>
                              </Box>
                            )}
                          />
                        ))
                      ) : (
                        <p className="pb-3 text-sm text-center text-gray-400">
                          No recipients defined.
                        </p>
                      )}
                      <FormHelperText>
                        If the patient&#39;s response meets form conditions,
                        send a notification SMS to the specified users
                        (optional).
                      </FormHelperText>
                    </FormControl>
                  </VStack>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button
                isDisabled={!isValid || Object.keys(errors).length > 0}
                onClick={handleSubmit(onCreate)}
                colorScheme="green"
                size="sm"
                my="auto"
                mx="2"
                isLoading={creationQueued || isCreateLoading}
                leftIcon={<FontAwesomeIcon icon={["fas", "circle-check"]} />}
              >
                Create Patient
              </Button>
              <Button
                onClick={onClose}
                colorScheme="red"
                size="sm"
                my="auto"
                leftIcon={<FontAwesomeIcon icon={["fas", "xmark-circle"]} />}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </>
      )}
    </Modal>
  );
}
