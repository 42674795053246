import { createRoot } from "react-dom/client";
import config from "@packages/config";
import sentryInit from "@packages/sentry";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BaseApp } from "BaseApp";

import "@fortawesome/fontawesome-free/js/all";

import "./index.css";
import "react-datepicker/dist/react-datepicker.css";

sentryInit({
  dsn: import.meta.env.VITE_SENTRY_DSN_DOCTOR_APP,
  release: `${config.version}-${config.commit}`,
});

const container = document.getElementById("root");

if (!container) {
  throw new Error("No app container found");
}
const root = createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30 * 1000,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <BaseApp />
  </QueryClientProvider>,
);
