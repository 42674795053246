import {
  IDeliveryStatus,
  IPatient,
  IPhoneDeliveryStatus,
  IScheduledSMS,
  ISMSTemplate,
  SMSInterval,
} from "@packages/types";

export function toPhoneDeliveryStatus(
  objs: IDeliveryStatus[],
): IPhoneDeliveryStatus {
  const phones: IPhoneDeliveryStatus = {};
  objs.forEach((status) => {
    if (!Object.keys(phones).includes(status.mobile)) {
      phones[status.mobile] = [status];
    } else {
      phones[status.mobile].push(status);
    }
  });
  return phones;
}

export function serialiseDeliveryStatus(obj: object): IDeliveryStatus {
  return {
    mobile: obj["mobile"],
    sid: obj["sid"],
    queueTS: new Date(obj["queue_ts"]),
    sentTS: obj["sent_ts"] ? new Date(obj["sent_ts"]) : undefined,
    deliveredTS: obj["delivered_ts"] ? new Date(obj["sent_ts"]) : undefined,
  };
}

export function flattenAllPatients(docPatients: {
  [email: string]: IPatient[];
}): IPatient[] {
  return Object.entries(docPatients).flatMap(([doctor, patients]) => {
    return patients.map((patient) => ({ ...patient, doctor }));
  });
}

export function serialiseSMSTemplate(obj: object): ISMSTemplate {
  return {
    id: obj["id"],
    name: obj["name"],
    message: obj["message"],
    createdAt: new Date(obj["created_at"]),
    updatedAt: new Date(obj["updated_at"]),
    isDefault: obj["is_default"],
  };
}

export function serialiseSMS(obj: object): IScheduledSMS {
  return {
    id: obj["id"],
    name: obj["name"],
    startDate: new Date(obj["start_date"]),
    occurrences: parseInt(obj["occurrences"]),
    interval: obj["interval"].toString() as SMSInterval,
    recipients: obj["recipients"]?.length > 0 ? obj["recipients"] : [],
    template: obj["template"],
    form: obj["form"],
    active: obj["active"],
    createdAt: new Date(obj["created_at"]),
    updatedAt: new Date(obj["updated_at"]),
    sentCount: parseInt(obj["sent_count"]),
  };
}
