import { HStack } from "@chakra-ui/react";

import { ActionButton } from "components/generic/actions/ActionButton";

interface IActionButtonProps {
  onEdit?: () => void;
  onRemove?: () => void;
  onExpand?: () => void;
  className?: string;
  disabled?: boolean;
}

export function ActionButtons({
  onEdit,
  onRemove,
  onExpand,
  className,
  disabled = false,
}: IActionButtonProps) {
  return (
    <HStack mx="2">
      {onEdit !== undefined && (
        <EditAction onEdit={onEdit} className={className} disabled={disabled} />
      )}
      {onRemove !== undefined && (
        <RemoveAction
          onRemove={onRemove}
          className={className}
          disabled={disabled}
        />
      )}
      {onExpand !== undefined && (
        <ExpandAction
          onExpand={onExpand}
          className={className}
          disabled={disabled}
        />
      )}
    </HStack>
  );
}

export function RemoveAction({
  onRemove,
  className,
  disabled = false,
}: IActionButtonProps) {
  if (onRemove === undefined) {
    return null;
  }
  return (
    <ActionButton
      icon={["fas", "trash"]}
      onClick={onRemove}
      className={className}
      disabled={disabled}
    />
  );
}

export function EditAction({
  onEdit,
  className,
  disabled = false,
}: IActionButtonProps) {
  if (onEdit === undefined) {
    return null;
  }
  return (
    <ActionButton
      icon={["fas", "pencil"]}
      onClick={onEdit}
      className={className}
      disabled={disabled}
    />
  );
}

export function ExpandAction({
  onExpand,
  className,
  disabled = false,
}: IActionButtonProps) {
  if (onExpand === undefined) {
    return null;
  }
  return (
    <ActionButton
      icon={["fas", "expand"]}
      onClick={onExpand}
      className={className}
      disabled={disabled}
    />
  );
}
