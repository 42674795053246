import { useEffect, useState } from "react";
import { Progress, useColorMode } from "@chakra-ui/react";
import { PaymentIntent } from "@packages/types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";

import { usePaymentConfig } from "../../api/hooks/payment";

import PaymentForm from "./PaymentForm";
const Payment = ({ amount, currency }: PaymentIntent) => {
  const { colorMode } = useColorMode();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const paymentConfig = usePaymentConfig();

  useEffect(() => {
    if (paymentConfig.data) {
      setStripePromise(loadStripe(paymentConfig.data.STRIPE_PUBLISHABLE_KEY));
    }
  }, [paymentConfig.data]);

  return (
    <>
      {stripePromise ? (
        <Elements
          stripe={stripePromise}
          options={{
            setupFutureUsage: "off_session",
            mode: "payment",
            amount: amount * 100,
            currency,
            paymentMethodCreation: "manual",
            appearance: { theme: colorMode === "dark" ? "night" : "stripe" },
          }}
        >
          <PaymentForm amount={amount} currency={currency} />
        </Elements>
      ) : (
        <div
          style={{
            width: "100%",
          }}
        >
          <Progress size="xs" isIndeterminate />
        </div>
      )}
    </>
  );
};

export default Payment;
