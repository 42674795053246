import { getAPIURL } from "./globalVars";

export interface SPAConfig {
  tenant: string;
  version: string;
  commit: string;
  v2Endpoint: string;
  adminEndpoint: string;
  auth0Domain: string;
  doctorClientID: string;
  adminClientID: string;
  auth0Audience: string;
}

function getEnv(): "dev" | "testing" | "prod" {
  if (!import.meta.env.PROD) {
    return "dev";
  } else if (import.meta.env.PROD && !import.meta.env.VITE_TAG) {
    return "testing";
  } else {
    return "prod";
  }
}

export function getConfig(): SPAConfig {
  const version = import.meta.env.VITE_VERSION ?? "testing";
  const commit = import.meta.env.VITE_COMMIT;
  const env = getEnv();
  const { adminEndpoint } = getAPIURL(env);
  return {
    tenant: import.meta.env.VITE_TENANT,
    version,
    commit,
    v2Endpoint: `${import.meta.env.VITE_API_URL}/v2`,
    auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    adminEndpoint,
    auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN,
    doctorClientID: import.meta.env.VITE_DOCTOR_AUTH0_CLIENT_ID,
    adminClientID:
      env !== "dev"
        ? import.meta.env.VITE_AUTH0_CID_ADMIN ?? ""
        : "1TcmCqtuT1AkDOUvJS6grPoVrl8jLBb9",
  };
}

export default getConfig();
