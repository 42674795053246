import nativeConfig from "@packages/config/native";
import spaConfig, { SPAConfig } from "@packages/config/spa";
import axios, { AxiosInstance } from "axios";

function getClient(base: string): AxiosInstance {
  return axios.create({
    baseURL: base,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function withAuth(token: string): object {
  return { headers: { Authorization: `Bearer ${token}` } };
}

const isNative = !!(window as any)?.platformAPI;
export const config = isNative ? nativeConfig : spaConfig;

export const apiClient = getClient(config.v2Endpoint);

// Create admin API client if not Native
let adminClient: AxiosInstance = getClient("");
if (!isNative) {
  adminClient = getClient((config as SPAConfig).adminEndpoint);
}

export { adminClient };
