import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldTypes } from "@packages/types";

// pick object properties
export const pick = (obj: { [key: string]: any }, keys: string[]) => {
  return keys.reduce((result, key) => {
    return {
      ...result,
      [key]: obj[key],
    };
  }, {});
};

export const getIcon = (type: FieldTypes, props?: any) => {
  switch (type) {
    case FieldTypes.Text:
      return (
        <FontAwesomeIcon
          icon={["fas", "i-cursor"]}
          className="w-4"
          {...props}
        />
      );
    case FieldTypes.Conditional:
      return (
        <FontAwesomeIcon
          icon={["fas", "toggle-off"]}
          className="w-4"
          {...props}
        />
      );
    case FieldTypes.DateTime:
      return (
        <FontAwesomeIcon
          icon={["fas", "calendar"]}
          className="w-4"
          {...props}
        />
      );
    case FieldTypes.MultiSelect:
      return (
        <FontAwesomeIcon icon={["fas", "list"]} className="w-4" {...props} />
      );
    case FieldTypes.Number:
      return <FontAwesomeIcon icon={["fas", "0"]} className="w-4" {...props} />;
    case FieldTypes.Paragraph:
      return (
        <FontAwesomeIcon
          icon={["fas", "align-justify"]}
          className="w-4"
          {...props}
        />
      );
    default:
      return (
        <FontAwesomeIcon
          icon={["fas", "question"]}
          className="w-4"
          {...props}
        />
      );
  }
};
