import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IActionButtonProps {
  onClick: (event) => void;
  icon: IconProp;
  className?: string;
  disabled?: boolean;
}

export function ActionButton({
  icon,
  onClick,
  className,
  disabled = false,
}: IActionButtonProps) {
  return (
    <button
      className={`flex rounded p-1 ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon
        icon={icon}
        className={`${disabled ? "text-gray-300" : ""}`}
      />
    </button>
  );
}
