import { useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ls from "local-storage";

interface IPreferencesModal {
  isOpen: boolean;
  onClose: () => void;
}

interface IPreferencesThemeButtonProps {
  onClick: () => void;
}

export function PreferencesThemeButton({
  onClick,
}: IPreferencesThemeButtonProps) {
  const isDarkMode = ls.get("doctor/darkMode") === true;

  return isDarkMode ? (
    <Button onClick={onClick}>
      <FontAwesomeIcon icon={["fas", "sun"]} />
      <p className="ml-2">Toggle Light</p>
    </Button>
  ) : (
    <Button onClick={onClick}>
      <FontAwesomeIcon icon={["fas", "moon"]} />
      <p className="ml-2">Toggle Dark</p>
    </Button>
  );
}

export function PreferencesModal({ isOpen, onClose }: IPreferencesModal) {
  const { colorMode, toggleColorMode } = useColorMode();

  const handleThemeToggle = () => {
    const isDarkMode = ls.get("doctor/darkMode") === true;
    ls.set("doctor/darkMode", !isDarkMode);
    updateTheme();
  };

  const updateTheme = () => {
    const isDarkMode = ls.get("doctor/darkMode") === true;

    if (isDarkMode) {
      if (colorMode === "light") {
        toggleColorMode();
      }
    } else if (!isDarkMode) {
      if (colorMode === "dark") {
        toggleColorMode();
      }
    }
  };

  useEffect(() => {
    updateTheme();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Preferences</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="10">
          <VStack spacing="3">
            <FormControl>
              <FormLabel>Theme (Experimental):</FormLabel>
              <PreferencesThemeButton onClick={handleThemeToggle} />
            </FormControl>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
