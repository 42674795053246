import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { toFormOut } from "@packages/api";
import { hasTesterRole, useAuthentication, UserRoles } from "@packages/auth";
import { useAllForms, useUpdateForm } from "api/hooks/schema";
import { useModalState } from "contexts/GlobalModalState";
import { FormTabs } from "utils/formEditor";

import { TopNav } from "components/generic/TopNav";
import { LoadingCube } from "components/ui/Loader";
import { SideNav } from "components/ui/SideNav";

import { Usage } from "./settings/Usage";
import { Billing, Checkout, Settings } from "./settings";

// Lazy loading for our views
const DoctorDash = React.lazy(() => import("./doctor/Home"));
const ManageFormView = React.lazy(() => import("./admin/Form/Forms"));
const ManageFormDetails = React.lazy(() => import("./admin/Form/FormDetails"));
const ManageFormSlides = React.lazy(() => import("./admin/Form/Slides"));
const ManageFormConditions = React.lazy(
  () => import("./admin/Form/Conditions"),
);
const ManageFormFlow = React.lazy(() => import("./admin/Form/Flow"));
const SchedulingView = React.lazy(
  () => import("./doctor/Messaging/Scheduling"),
);
const TemplateView = React.lazy(() => import("./doctor/Messaging/Templating"));
const PatientsView = React.lazy(() => import("./doctor/PatientsView"));
const VoiceTranscriptionView = React.lazy(
  () => import("./doctor/VoiceTranscriptionView"),
);

export function DefaultLayout() {
  const { user } = useAuthentication();
  const { data: forms, isLoading } = useAllForms();
  const { mutate: updateForm } = useUpdateForm();

  const {
    editingFormID,
    editingFormDetails,
    setEditingFormDetails,
    editingFormTab,
    setEditingFormTab,
    activeFormID,
    setActiveFormID,
  } = useModalState();

  const navigate = useNavigate();

  // When the form editor is closed, save changes
  useEffect(() => {
    if (editingFormID === undefined) {
      if (editingFormDetails) {
        updateForm({
          uuid: editingFormDetails.id,
          form: toFormOut(editingFormDetails),
        });
        setEditingFormDetails(undefined);
        setEditingFormTab(FormTabs.FormDetails);
        navigate("/admin/forms");
      }
    } else {
      if (editingFormDetails === undefined) {
        navigate(`/admin/forms/${editingFormTab}`);
      }
    }
  }, [
    editingFormDetails,
    editingFormID,
    editingFormTab,
    forms,
    isLoading,
    navigate,
    setEditingFormDetails,
    setEditingFormTab,
    updateForm,
  ]);

  // When a form editor tab is selected, redirect
  useEffect(() => {
    if (window.location.hash.includes("#/admin/forms/")) {
      if (editingFormID) {
        navigate(`/admin/forms/${editingFormTab}`);
      } else {
        navigate("/admin/forms");
      }
    }
  }, [editingFormID, editingFormTab, navigate]);

  // When leaving a patient list, reset active form
  useEffect(() => {
    if (activeFormID && !window.location.hash.includes("#/patients/list")) {
      setActiveFormID(undefined);
    }
  });

  // Check if the user has admin access
  let doctorHasAdmin = false;
  if (user) {
    // TODO: config the role name
    doctorHasAdmin =
      user["https://api.otoshealth.com/roles"]?.includes(UserRoles.Manager) ??
      false;
  }

  const isTester = hasTesterRole();

  return (
    <>
      <TopNav />
      <HStack h="full" style={{ marginTop: 0 }}>
        <Box h="full" minW="210px" w="210px">
          <SideNav isAdmin={doctorHasAdmin} />
        </Box>
        <VStack h="full" w="0" className="grow !ml-0">
          <Box mt="0" w="100%" zIndex="4" />
          <HStack className="!mt-0 !pt-6" h="full" w="full">
            <Box
              className="w-full h-full mb-8 overflow-auto"
              sx={{ marginInlineStart: "0 !important" }}
            >
              <React.Suspense fallback={<LoadingCube />}>
                <Routes>
                  <Route path="/" element={<DoctorDash />} />
                  <Route path="/patients/list" element={<PatientsView />} />
                  <Route
                    path="/patients/scheduling"
                    element={<SchedulingView />}
                  />
                  <Route
                    path="/patients/templates"
                    element={<TemplateView />}
                  />
                  {isTester && (
                    <Route
                      path="/patients/voice-transcription"
                      element={<VoiceTranscriptionView />}
                    />
                  )}
                  {doctorHasAdmin && (
                    <>
                      <Route path="/admin/forms" element={<ManageFormView />} />
                      <Route
                        path="/admin/forms/details"
                        element={<ManageFormDetails />}
                      />
                      <Route
                        path="/admin/forms/slides"
                        element={<ManageFormSlides />}
                      />
                      <Route
                        path="/admin/forms/conditions"
                        element={<ManageFormConditions />}
                      />
                      <Route
                        path="/admin/forms/flow"
                        element={<ManageFormFlow />}
                      />
                    </>
                  )}
                  <Route path="settings" element={<Settings />}>
                    <Route path="Usage" element={<Usage />} />
                    <Route path="checkout" element={<Checkout />} />
                    <Route path="billing" element={<Billing />} />
                  </Route>
                </Routes>
              </React.Suspense>
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </>
  );
}
