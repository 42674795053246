import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  FieldTypes,
  FlowActions,
  IForm,
  IPatient,
  IResponse,
  SMSStatusFlag,
} from "@packages/types";
import { isEmpty } from "lodash";

interface IRowColorAndIcon {
  tooltip?: string;
  colour?: string;
  icon: IconProp;
}

export enum ExportFilter {
  OnlyEntered = "Showing Only Entered",
  ExcludeScreening = "Exclude Screening Questions",
}

export function getRowIconTooltipAndColour(
  patient: IPatient,
  status?: SMSStatusFlag,
): IRowColorAndIcon {
  // First check if there is an existing form response
  if (
    patient?.response &&
    patient.response.length > 0 &&
    !isEmpty(patient.response[0]?.slides)
  ) {
    return {
      tooltip: "Patient has responded",
      colour: "green.100",
      icon: ["fas", "square-check"],
    };
  }
  // Looks like there is not, check the SMS status
  switch (status) {
    case SMSStatusFlag.Queued:
      return {
        tooltip: "Message Queued",
        icon: ["fas", "clock"],
      };
    case SMSStatusFlag.Sent:
      return {
        tooltip: "Message Sent",
        icon: ["fas", "paper-plane"],
      };
    case SMSStatusFlag.Delivered:
      return {
        tooltip: "Waiting for Patient",
        icon: ["fas", "user-clock"],
      };
    case SMSStatusFlag.Invalid:
      return {
        tooltip: "Invalid mobile number",
        colour: "black.100",
        icon: ["fas", "phone-slash"],
      };
    default:
      return {
        tooltip: "Unknown",
        icon: ["fas", "circle-question"],
      };
  }
}

// Return slide IDs ordered by form flow
export function getSlidesOrdered(
  startingSlideID: string,
  form: IForm,
): string[] {
  const slides: string[] = [];
  slides.push(startingSlideID);
  const flow = form.flow?.find((f) => f.src === startingSlideID);
  if (flow) {
    flow.rules.forEach((r) => {
      r.then.forEach((t) => {
        if (t.action === FlowActions.Redirect) {
          slides.push(...getSlidesOrdered(t.arguments["slide"], form));
        }
      });
    });
    if (flow.defaultDest !== "end" && flow.defaultDest !== "unselected") {
      if (slides.find((s) => s === flow.defaultDest) === undefined) {
        slides.push(...getSlidesOrdered(flow.defaultDest, form));
      }
    }
  }
  return slides;
}

export const isFieldEmpty = (value: any) => {
  if (Array.isArray(value)) {
    return value.length < 1;
  }
  return value === "" || !value || value === "No";
};

export function getSlideFilterFunc(
  schema: IForm,
  response: IResponse,
  filter?: ExportFilter,
) {
  switch (filter) {
    case ExportFilter.OnlyEntered:
      return (slideKey: string) =>
        !(schema.slides[slideKey].fields ?? []).every((f) =>
          isFieldEmpty(response.slides[`${slideKey}_${f.id}`]),
        );
    case ExportFilter.ExcludeScreening:
      return (slideKey: string) =>
        schema.slides[slideKey].fields?.length == 1 &&
        (schema.slides[slideKey].fields ?? [])[0].type == FieldTypes.Conditional
          ? false
          : getSlideFilterFunc(
              schema,
              response,
              ExportFilter.OnlyEntered,
            )(slideKey);
    default:
      return () => true;
  }
}
