import { createContext, ReactNode, useContext, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { IPatient, IResponse } from "@packages/types";
import { IForm } from "@packages/types";

import { ExternalFieldModal } from "components/admin/ManageForms/FormEdit/FlowManagement/ExternalFieldModal";
import { CreatePatientModalWrapper } from "components/doctor/NewPatientModal";
import { ResponseExport } from "components/doctor/Patient/ViewModal/ExportModal";
import { ViewPatientModal } from "components/doctor/Patient/ViewModal/ViewPatientModal";
import { PreferencesModal } from "components/generic/Profile/PreferencesModal";

import {
  ConfirmModal,
  ConfirmTypes,
  IAdditionalButton,
} from "../components/generic/ConfirmModal";
import {
  AlertTypes,
  ConnectionAlert,
} from "../components/generic/ConnectionAlert";

interface IConfirmModalProps {
  asyncFunc?: any;
  asyncProps?: any;
  then?: () => void;
  confirmType: ConfirmTypes;
  buttons?: IAdditionalButton[];
}

interface IFieldsModalProps {
  onSelect: (accessor: string) => void;
  counters?: boolean;
}

interface IGenericModalContext {
  openFieldModal: (props: IFieldsModalProps) => void;
  openConfirmModal: (props: IConfirmModalProps) => void;
  openAlert: (type: AlertTypes) => void;
  closeAlert: () => void;
  openPreferencesModal: () => void;
  openExportModal: (
    patient: IPatient,
    response: IResponse,
    schema?: IForm,
  ) => void;
}

export const ModalProps = createContext<IGenericModalContext>({
  openFieldModal: () => {
    return;
  },
  openConfirmModal: () => {
    return;
  },
  openAlert: () => {
    return;
  },
  closeAlert: () => {
    return;
  },
  openPreferencesModal: () => {
    return;
  },
  openExportModal: () => {
    return;
  },
});

// Quick hook for ease of use
export function useGenericModals(): IGenericModalContext {
  const ctx = useContext(ModalProps);
  if (ctx === undefined) {
    throw new Error(
      "useGenericModals must be used within a GenericModalsProvider",
    );
  }
  return ctx as IGenericModalContext;
}

// Generic modals for confirmation, alerts, etc.
export function GenericModalsProvider({ children }: { children: ReactNode }) {
  const {
    isOpen: preferencesIsOpen,
    onOpen: preferencesOnOpen,
    onClose: preferencesOnClose,
  } = useDisclosure();
  const [fieldModalProps, setFieldModalProps] = useState<
    IFieldsModalProps | undefined
  >(undefined);
  const [schema, setSchema] = useState<IForm | undefined>(undefined);
  const [selectedPatient, setSelectedPatient] = useState<IPatient | undefined>(
    undefined,
  );
  const [selectedResponse, setSelectedResponse] = useState<
    IResponse | undefined
  >(undefined);
  const [selectedConfirm, setSelectedConfirm] = useState<
    IConfirmModalProps | undefined
  >(undefined);
  const [selectedAlert, setSelectedAlert] = useState<AlertTypes | undefined>(
    undefined,
  );
  // State to detect when alert was opened.
  // When true detected, immediately set to false in ConnectionAlert useEffect.
  const [alertOpened, setAlertOpened] = useState<boolean>(false);

  return (
    <ModalProps.Provider
      value={{
        openFieldModal: (props: IFieldsModalProps) => setFieldModalProps(props),
        openConfirmModal: (props: IConfirmModalProps) =>
          setSelectedConfirm(props),
        openAlert: (type: AlertTypes) => {
          setSelectedAlert(type);
          setAlertOpened(true);
        },
        closeAlert: () => setSelectedAlert(undefined),
        openPreferencesModal: preferencesOnOpen,
        openExportModal: (
          patient: IPatient,
          response: IResponse,
          schema?: IForm,
        ) => {
          setSelectedResponse(response);
          setSelectedPatient(patient);
          setSchema(schema);
        },
      }}
    >
      {fieldModalProps && (
        <ExternalFieldModal
          isOpen={fieldModalProps !== undefined}
          onClose={() => setFieldModalProps(undefined)}
          onSelect={fieldModalProps?.onSelect}
          counters={fieldModalProps?.counters}
        />
      )}
      <ConnectionAlert
        isOpen={selectedAlert !== undefined}
        onClose={() => setSelectedAlert(undefined)}
        type={selectedAlert}
        alertOpened={alertOpened}
        setAlertOpened={(value: boolean) => setAlertOpened(value)}
      />
      {selectedConfirm !== undefined && (
        <ConfirmModal
          isOpen={selectedConfirm !== undefined}
          onClose={() => setSelectedConfirm(undefined)}
          asyncFunc={selectedConfirm?.asyncFunc}
          asyncProps={selectedConfirm?.asyncProps}
          then={selectedConfirm?.then}
          type={selectedConfirm?.confirmType}
          buttons={selectedConfirm?.buttons}
        />
      )}
      {/* <DoctorProfileModal /> */}
      <ViewPatientModal />
      <CreatePatientModalWrapper />
      {/* <NewSlideModal /> */}
      {selectedResponse !== undefined && (
        <ResponseExport
          isOpen={selectedResponse !== undefined}
          onClose={() => setSelectedResponse(undefined)}
          schema={schema}
          patient={selectedPatient}
          response={selectedResponse}
        />
      )}
      <PreferencesModal
        isOpen={preferencesIsOpen}
        onClose={preferencesOnClose}
      />
      {children}
    </ModalProps.Provider>
  );
}
