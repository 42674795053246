import { createContext, ReactNode, useContext, useState } from "react";
import { IEditingCondition, IPatient } from "@packages/types";
import {
  ICreatingFormProps,
  IFormDetails,
  IScheduledSMS,
  ISMSTemplate,
} from "@packages/types";
import { FormTabs } from "utils/formEditor";

import { IValueProps } from "components/admin/ManageForms/FormEdit/ConditionManagement/FlowVariables";

type IChangeFunction<T> = (record?: T) => void;

// Global modal state type
interface IGlobalModalState {
  editingFormID?: string;
  editingFormTab?: FormTabs;
  editingFormDetails?: IFormDetails;
  editingSlideID?: string;
  editingFieldKey?: number;
  editingValue?: IValueProps;
  editingCondition?: IEditingCondition;
  formSavable?: boolean;
  activeFormID?: string;
  activePatient?: IPatient;
  activeSMSTemplate?: ISMSTemplate;
  activeScheduledSMS?: IScheduledSMS;
  // Creating types
  creatingForm?: ICreatingFormProps;
  creatingPatient?: boolean;
  creatingSMSTemplate?: boolean;
  creatingScheduledSMS?: boolean;
  // Funcs for activating each type
  setActiveFormID: IChangeFunction<string>;
  setEditingFormID: IChangeFunction<string>;
  setEditingFormTab: IChangeFunction<FormTabs>;
  setEditingFormDetails: IChangeFunction<IFormDetails>;
  setEditingSlideID: IChangeFunction<string>;
  setEditingFieldKey: IChangeFunction<number>;
  setEditingValue: IChangeFunction<IValueProps>;
  setEditingCondition: IChangeFunction<IEditingCondition>;
  setFormSavable: IChangeFunction<boolean>;
  setActivePatient: IChangeFunction<IPatient>;
  setActiveSMSTemplate: IChangeFunction<ISMSTemplate>;
  setActiveScheduledSMS: IChangeFunction<IScheduledSMS>;
  // Funcs for activating each type
  setCreatingForm: IChangeFunction<ICreatingFormProps>;
  setCreatingPatient: IChangeFunction<boolean>;
  startCreatingPatient: () => void;
  setCreatingSMSTemplate: IChangeFunction<boolean>;
  startCreatingSMSTemplate: () => void;
  setCreatingScheduledSMS: IChangeFunction<boolean>;
  startCreatingScheduledSMS: () => void;
}

export const GlobalModalState = createContext<IGlobalModalState>({
  setActiveFormID: () => {
    return;
  },
  setEditingFormID: () => {
    return;
  },
  setEditingFormTab: () => {
    return;
  },
  setEditingFormDetails: () => {
    return;
  },
  setEditingSlideID: () => {
    return;
  },
  setEditingFieldKey: () => {
    return;
  },
  setEditingValue: () => {
    return;
  },
  setEditingCondition: () => {
    return;
  },
  setFormSavable: () => {
    return;
  },
  setActivePatient: () => {
    return;
  },
  setActiveSMSTemplate: () => {
    return;
  },
  setActiveScheduledSMS: () => {
    return;
  },
  setCreatingForm: () => {
    return;
  },
  setCreatingPatient: () => {
    return;
  },
  startCreatingPatient: () => {
    return;
  },
  setCreatingSMSTemplate: () => {
    return;
  },
  startCreatingSMSTemplate: () => {
    return;
  },
  setCreatingScheduledSMS: () => {
    return;
  },
  startCreatingScheduledSMS: () => {
    return;
  },
});

export function useModalState(): IGlobalModalState {
  const ctx = useContext(GlobalModalState);
  if (ctx === undefined) {
    throw new Error(
      "useModalState must be used within a GlobalModalStateProvider",
    );
  }
  return ctx as IGlobalModalState;
}

export function GlobalModalStateProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [activeFormID, setActiveFormID] = useState<string | undefined>();
  const [editingFormID, setEditingFormID] = useState<string | undefined>();
  const [editingFormTab, setEditingFormTab] = useState<FormTabs | undefined>(
    FormTabs.FormDetails,
  );
  const [editingFieldKey, setEditingFieldKey] = useState<number | undefined>(
    undefined,
  );
  const [editingSlideID, setEditingSlideID] = useState<string | undefined>(
    undefined,
  );
  const [editingValue, setEditingValue] = useState<IValueProps | undefined>(
    undefined,
  );
  const [editingCondition, setEditingCondition] = useState<
    IEditingCondition | undefined
  >(undefined);
  const [formSavable, setFormSavable] = useState<boolean | undefined>(true);
  const [activePatient, setActivePatient] = useState<IPatient | undefined>();
  const [activeSMSTemplate, setActiveSMSTemplate] = useState<
    ISMSTemplate | undefined
  >();
  const [activeScheduledSMS, setActiveScheduledSMS] = useState<
    IScheduledSMS | undefined
  >();
  const [creatingForm, setCreatingForm] = useState<
    ICreatingFormProps | undefined
  >();
  const [editingFormDetails, setEditingFormDetails] = useState<
    IFormDetails | undefined
  >();
  const [creatingPatient, setCreatingPatient] = useState<boolean | undefined>(
    false,
  );
  const [creatingSMSTemplate, setCreatingSMSTemplate] = useState<
    boolean | undefined
  >(false);
  const [creatingScheduledSMS, setCreatingScheduledSMS] = useState<
    boolean | undefined
  >(false);

  const startCreatingPatient = () => setCreatingPatient(true);
  const startCreatingScheduledSMS = () => setCreatingScheduledSMS(true);
  const startCreatingSMSTemplate = () => setCreatingSMSTemplate(true);

  const values = {
    activeFormID,
    editingFormID,
    editingFormTab,
    editingFormDetails,
    editingFieldKey,
    editingSlideID,
    editingValue,
    editingCondition,
    formSavable,
    activePatient,
    activeSMSTemplate,
    activeScheduledSMS,
    creatingForm,
    creatingPatient,
    creatingSMSTemplate,
    creatingScheduledSMS,
    setActiveFormID,
    setEditingFormID,
    setEditingFormTab,
    setEditingFormDetails,
    setEditingFieldKey,
    setEditingSlideID,
    setEditingValue,
    setEditingCondition,
    setFormSavable,
    setActivePatient,
    setActiveSMSTemplate,
    setActiveScheduledSMS,
    setCreatingForm,
    setCreatingPatient,
    startCreatingPatient,
    setCreatingSMSTemplate,
    startCreatingSMSTemplate,
    setCreatingScheduledSMS,
    startCreatingScheduledSMS,
  };

  return (
    <GlobalModalState.Provider value={values}>
      {children}
    </GlobalModalState.Provider>
  );
}
