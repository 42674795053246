import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "@chakra-ui/layout";
import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";

import { useBalance, useTransaction } from "../../../api/hooks/payment";
const capitalizeFirstLetter = (str) => {
  if (str.length === 0) {
    return str;
  }

  return str[0].toUpperCase() + str.slice(1);
};
export const Billing = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { data: balance } = useBalance(user?.email as string);
  const { data, isLoading: transactionsIsLoading } = useTransaction(
    user?.email as string,
  );
  const { transactions } = data || {};

  return (
    <Stack spacing="10" pt="10px">
      <Stack>
        <h2>Plan details</h2>
        <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: "5", md: "6" }}>
          <Box
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
            bg="bg.surface"
            borderRadius="lg"
            boxShadow="sm"
          >
            <Stack>
              <Text textStyle="sm" color="fg.muted">
                Credit remaining:
              </Text>
              {balance?.formattedBalance ? (
                <Heading size={{ base: "sm", md: "md" }}>
                  ${balance?.formattedBalance}
                </Heading>
              ) : (
                <Skeleton height="40px" />
              )}
              <Button
                colorScheme="teal"
                variant="outline"
                onClick={() => {
                  navigate("/settings/checkout");
                }}
              >
                Add Credit
              </Button>
            </Stack>
          </Box>
          <Box
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
            bg="bg.surface"
            borderRadius="lg"
            boxShadow="sm"
          >
            <Stack>
              <Text textStyle="sm" color="fg.muted">
                Your plan:
              </Text>
              <Heading size={{ base: "sm", md: "md" }}>Pay As You Go</Heading>
              <Text textStyle="sm" color="fg.muted" hidden>
                <Link color="teal.600">Upgrade</Link> to receive better rates
              </Text>
            </Stack>
          </Box>
          <Box
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
            bg="bg.surface"
            borderRadius="lg"
            boxShadow="sm"
          >
            <Stack>
              <Text textStyle="sm" color="rgba(98.43%,67.45%,9.41%,1)">
                <FontAwesomeIcon icon={["fas", "lightbulb"]} /> Auto-reload is
                turned off
              </Text>
              <Text textStyle="xs" color="fg.muted">
                Keep the transcripts coming. Automatically refresh your balance
                when it's low.
              </Text>
              <Text textStyle="sm" color="tomato" hidden>
                <FontAwesomeIcon icon={["fas", "edit"]} /> Turn on Auto-reload
              </Text>
            </Stack>
          </Box>
        </SimpleGrid>
      </Stack>
      <Stack>
        <h2>Billing history</h2>
        {transactionsIsLoading ? (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        ) : (
          <TableContainer overflowY={"auto"} maxH={500}>
            <Table variant="simple">
              <Thead position="sticky" top={-1} zIndex="docked">
                <Tr>
                  <Th>Invoice date</Th>
                  <Th>Amount</Th>
                  <Th>Type</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactions?.map((transaction, index) => (
                  <Tr key={index}>
                    <Td>
                      {DateTime.fromSeconds(transaction.created).toFormat(
                        "ccc, dd LLL yyyy",
                      )}
                    </Td>
                    <Td>${(transaction.amount / 100).toFixed(2)}</Td>
                    <Td>{capitalizeFirstLetter(transaction.type)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Stack>
  );
};
