import { serialiseUsers } from "@packages/api";
import config from "@packages/config";
import {
  IColumns,
  ICreatingUser,
  IDoctor,
  IUpdatingUser,
  Roles,
} from "@packages/types";

import { adminClient, apiClient, withAuth } from "../client";
import {
  serialiseArrayResponse,
  serialiseColumns,
  serialiseDoctor,
  serialiseObjectResponse,
} from "../utils/serialisation";

export const fetchAllUsers = async (token: string): Promise<IDoctor[]> => {
  const { data } = await apiClient.get("/user/users", withAuth(token));
  return serialiseArrayResponse<IDoctor>(data, serialiseDoctor);
};

export const fetchColumns = async (token: string): Promise<IColumns> => {
  const { data } = await apiClient.get("/user/cols", withAuth(token));
  return serialiseObjectResponse<IColumns>(data, serialiseColumns);
};

export const updateColumns = async (token: string, columns: IColumns) => {
  await apiClient.put("/user/cols", columns, withAuth(token));
};

export const fetchAuth0Users = async (token: string) => {
  const { data } = await adminClient.get(
    `/users/${config.tenant}`,
    withAuth(token),
  );
  return serialiseUsers(data);
};

export const createAuth0User = async (token: string, user: ICreatingUser) => {
  const response = await adminClient.post(
    `/users/${config.tenant}/${user.email}`,
    JSON.stringify({ role: Roles[user.role] }),
    withAuth(token),
  );
  if (response.status !== 200 && response.status !== 201) {
    console.error(response);
    throw new Error("Error creating user");
  }
  return response.data;
};

export const updateAuth0User = async (token: string, user: IUpdatingUser) => {
  await adminClient.put(`/users/${config.tenant}`, user, withAuth(token));
};
