import { Control, useController, UseFormSetValue } from "react-hook-form";
import { FormHelperText, FormLabel, Select } from "@chakra-ui/react";
import { isFormValid } from "@packages/api";
import { IForm } from "@packages/types";

export const FormSelect = ({
  control,
  forms,
  setValue,
  SMSTemplateFieldName = "templateID",
}: {
  control: Control<any, any>;
  forms: IForm[];
  setValue: UseFormSetValue<any>;
  SMSTemplateFieldName?: string;
}) => {
  const {
    field: { onChange, value },
  } = useController({
    control,
    name: "form",
    rules: { required: true },
  });

  return (
    <>
      <FormLabel>Form</FormLabel>
      <Select
        aria-label="form-select"
        value={value}
        onChange={(e) => {
          const formId = e.target.value;
          const form = forms?.find(({ id }) => id === formId);
          if (form?.templateID) {
            setValue(SMSTemplateFieldName, form.templateID);
          }
          onChange(formId, "form");
        }}
      >
        {forms?.map((form, key) => {
          if (isFormValid(form, forms)) {
            return (
              <option
                key={key}
                value={form.id}
                aria-label={`${form.name} option`}
              >
                {form.name}
              </option>
            );
          } else {
            return null;
          }
        })}
      </Select>
      <FormHelperText>The form to be completed by the patient.</FormHelperText>
    </>
  );
};
