import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { IMedspecField, IMedspecSlide, IResponse } from "@packages/types";
import { renderFieldType } from "misc";

interface ISlideItemProps {
  response: IResponse;
  slide: IMedspecSlide;
  slideID: string;
}

export function SlideFieldsItem({ response, slide, slideID }: ISlideItemProps) {
  // Determines whether slide is present in response.
  const slidePresent = () => {
    return Object.keys(response.slides).some((key) => key.includes(slideID));
  };

  const renderField = (field: IMedspecField, key: number) => {
    const value: any = response.slides[`${slideID}_${field.id}`];
    if (value === undefined) {
      return null;
    }

    return (
      <Tr key={key}>
        <Td
          style={{
            textWrap: "wrap",
          }}
        >
          <p className="font-semibold">{field.name}</p>
        </Td>
        <Td
          style={{
            textWrap: "wrap",
          }}
        >
          <Text color={"green.600"} className="font-semibold">
            {renderFieldType(value, field.type)}
          </Text>
        </Td>
      </Tr>
    );
  };

  if (slidePresent()) {
    return (
      <Box
        flexGrow="1"
        // flexBasis="400px"
        rounded="md"
        padding="3"
        border="1px"
        borderColor="gray.300"
      >
        <p className="mx-2 text-lg font-semibold grow">{slide.name}</p>
        <Table
          className="table my-2"
          style={{
            tableLayout: "fixed",
            width: 800,
          }}
        >
          <Thead>
            <Tr>
              <Th>Field Name</Th>
              <Th>Patient Response</Th>
            </Tr>
          </Thead>
          <Tbody>
            {slide.fields !== undefined && slide.fields?.map(renderField)}
          </Tbody>
        </Table>
      </Box>
    );
  } else {
    return null;
  }
}
