import {
  cloneForm,
  createFlowStep,
  createPostCondition,
  createSlide,
  deleteFlowStep,
  deletePostCondition,
  deleteSlide,
  deregisterForm,
  fetchAllForms,
  fetchForm,
  registerAction,
  registerForm,
  updateFlowStep,
  updateForm,
  updatePostCondition,
  updateSlide,
} from "@packages/api";
import { useAuthToken } from "@packages/auth";
import {
  IAction,
  IFlowItem,
  IForm,
  IFormOut,
  IMedspecSlideOut,
  IPostRuleOut,
} from "@packages/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useError } from "contexts/AlertContext";
import { useModalState } from "contexts/GlobalModalState";

export const useAllForms = () => {
  const { token } = useAuthToken();
  const { error } = useError();
  return useQuery<IForm[], undefined>(
    ["forms"],
    async () => fetchAllForms(token),
    { onError: error },
  );
};

export const useActiveForm = () => {
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useQuery<IForm | undefined, undefined>(
    ["forms", editingFormID],
    () =>
      editingFormID
        ? fetchForm(editingFormID)
        : Promise.reject(`useActiveForm: No form ID provided`),
    { onError: error, enabled: !!editingFormID },
  );
};

export const useCreateForm = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation((uuid: string) => registerForm(token, uuid), {
    onSuccess: (data) => {
      queryClient.setQueryData(["forms"], (cachedData: IForm[] | undefined) =>
        cachedData ? [...cachedData, data] : cachedData,
      );
    },
    onError: error,
  });
};

export const useUpdateForm = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation(
    ({ uuid, form }: { uuid: string; form: IFormOut }) => {
      return updateForm(token, uuid, form);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          ["forms"],
          (cachedData: IForm[] | undefined) => {
            return cachedData
              ? [
                  ...cachedData.filter((form: IForm) => form.id !== data.id),
                  data,
                ]
              : cachedData;
          },
        );
      },
      onError: error,
    },
  );
};

export const useDeleteForm = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation((uuid: string) => deregisterForm(token, uuid), {
    onSuccess: (formId) => {
      queryClient.setQueryData(["forms"], (cachedData: IForm[] | undefined) =>
        cachedData
          ? cachedData.filter((form: IForm) => form.id !== formId)
          : cachedData,
      );
    },
    onError: error,
  });
};

export const useCloneForm = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { error } = useError();
  return useMutation((form: IForm) => cloneForm(token, form), {
    onSuccess: (data) => {
      queryClient.setQueryData(["forms"], (cachedData: IForm[] | undefined) =>
        cachedData ? [...cachedData, data] : cachedData,
      );
    },
    onError: error,
  });
};

// Actions
export const useRegisterAction = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    ({ uuid, action }: { uuid: string; action: IAction }) =>
      registerAction(token, uuid, action),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          ["forms", editingFormID],
          (cachedData: IForm | undefined) => {
            if (cachedData) {
              cachedData.actions = data;
            }
            return cachedData;
          },
        );
      },
      onError: error,
    },
  );
};

// Slides
const slidesOnSuccessHandler = (queryClient, editingFormID) => (data) => {
  if (!data?.id) {
    return;
  }
  queryClient.setQueryData(
    ["forms", editingFormID],
    (cachedData: IForm | undefined) => {
      if (!cachedData) {
        return cachedData;
      }
      return {
        ...cachedData,
        slides: { ...cachedData.slides, [data.id]: data },
      };
    },
  );
};
export const useCreateSlide = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    (slide: IMedspecSlideOut) => {
      if (!editingFormID) {
        return Promise.reject("No form selected");
      }
      return createSlide(token, editingFormID, slide);
    },
    {
      onSuccess: slidesOnSuccessHandler(queryClient, editingFormID),
      onError: error,
    },
  );
};

export const useUpdateSlide = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    (slide: IMedspecSlideOut) => {
      if (!editingFormID) {
        return Promise.reject("No form selected");
      }
      return updateSlide(token, editingFormID, slide);
    },
    {
      onSuccess: slidesOnSuccessHandler(queryClient, editingFormID),
      onError: error,
    },
  );
};

export const useDeleteSlide = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    (slide: string) => {
      if (!editingFormID) {
        return Promise.reject("No form selected");
      }
      return deleteSlide(token, editingFormID, slide);
    },
    {
      onSuccess: (slideId) => {
        queryClient.setQueryData(
          ["forms", editingFormID],
          (cachedData: IForm | undefined) => {
            if (!cachedData) {
              return cachedData;
            }
            delete cachedData.slides[slideId];
            return cachedData;
          },
        );
      },
      onError: error,
    },
  );
};

export const useDeleteAllSlides = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    async (slides: string[]) => {
      if (!editingFormID) {
        return Promise.reject("No form selected");
      }
      return await Promise.all(
        slides.map((slide) => deleteSlide(token, editingFormID, slide)),
      );
    },
    {
      onSuccess: (slideIds) => {
        queryClient.setQueryData(
          ["forms", editingFormID],
          (cachedData: IForm | undefined) => {
            if (!cachedData) {
              return cachedData;
            }
            slideIds.forEach((slideId) => delete cachedData.slides[slideId]);
            return cachedData;
          },
        );
      },
      onError: error,
    },
  );
};

// Flow
export const useCreateFlowStep = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    (flow: IFlowItem) =>
      editingFormID
        ? createFlowStep(token, editingFormID, flow)
        : Promise.reject("No form selected"),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          ["forms", editingFormID],
          (cachedData: IForm | undefined) =>
            cachedData
              ? {
                  ...cachedData,
                  flow:
                    cachedData.flow && Array.isArray(cachedData.flow)
                      ? [...cachedData.flow, data]
                      : [data],
                }
              : cachedData,
        );
      },
      onError: error,
    },
  );
};

export const useUpdateFlowStep = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    (flow: IFlowItem) =>
      editingFormID
        ? updateFlowStep(token, editingFormID, flow)
        : Promise.reject("No form selected"),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          ["forms", editingFormID],
          (cachedData: IForm | undefined) =>
            cachedData
              ? {
                  ...cachedData,
                  flow:
                    cachedData.flow && Array.isArray(cachedData.flow)
                      ? [
                          ...cachedData.flow.filter((f) => f.src !== data.src),
                          data,
                        ]
                      : [data],
                }
              : cachedData,
        );
      },
      onError: error,
    },
  );
};

export const useDeleteFlowStep = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    (flow: string) =>
      editingFormID
        ? deleteFlowStep(token, editingFormID, flow)
        : Promise.reject("No form selected"),
    {
      onSuccess: (flowId) => {
        queryClient.setQueryData(
          ["forms", editingFormID],
          (cachedData: IForm | undefined) =>
            cachedData?.flow
              ? {
                  ...cachedData,
                  flow: cachedData.flow.filter((f) => f.src !== flowId),
                }
              : cachedData,
        );
      },
      onError: error,
    },
  );
};

// Postconditions
const postConditionOnSuccessHandler =
  (queryClient, editingFormID) => (data) => {
    if (!data?.id) {
      return;
    }
    queryClient.setQueryData(
      ["forms", editingFormID],
      (cachedData: IForm | undefined) =>
        cachedData
          ? {
              ...cachedData,
              postConditions: { ...cachedData.postConditions, [data.id]: data },
            }
          : cachedData,
    );
  };

export const useCreatePostCondition = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    (postcondition: IPostRuleOut) =>
      editingFormID
        ? createPostCondition(token, editingFormID, postcondition)
        : Promise.reject("No form selected"),
    {
      onSuccess: postConditionOnSuccessHandler(queryClient, editingFormID),
      onError: error,
    },
  );
};

export const useUpdatePostCondition = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    (postcondition: IPostRuleOut) =>
      editingFormID
        ? updatePostCondition(token, editingFormID, postcondition)
        : Promise.reject("No form selected"),
    {
      onSuccess: postConditionOnSuccessHandler(queryClient, editingFormID),
      onError: error,
    },
  );
};

export const useDeletePostCondition = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    (postcondition: string) =>
      editingFormID
        ? deletePostCondition(token, editingFormID, postcondition)
        : Promise.reject("No form selected"),
    {
      onSuccess: (postcondition) => {
        queryClient.setQueryData(
          ["forms", editingFormID],
          (cachedData: IForm | undefined) => {
            if (cachedData?.postConditions) {
              const { postConditions } = cachedData;
              delete postConditions[postcondition];
            }
            return cachedData;
          },
        );
      },
      onError: error,
    },
  );
};

export const useDeleteAllPostConditions = () => {
  const queryClient = useQueryClient();
  const { token } = useAuthToken();
  const { editingFormID } = useModalState();
  const { error } = useError();
  return useMutation(
    async (postconditions: string[]) =>
      editingFormID
        ? await Promise.all(
            postconditions.map((con) =>
              deletePostCondition(token, editingFormID, con),
            ),
          )
        : Promise.reject("No form selected"),
    {
      onSuccess: (postconditionIds) => {
        queryClient.setQueryData(
          ["forms", editingFormID],
          (cachedData: IForm | undefined) => {
            if (!cachedData) return cachedData;

            const { postConditions } = cachedData;

            if (!postConditions) return cachedData;

            postconditionIds.forEach((postcondition) => {
              delete postConditions[postcondition];
            });
            return cachedData;
          },
        );
      },
      onError: error,
    },
  );
};
