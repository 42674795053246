import { useAuth0 } from "@auth0/auth0-react";
import { User } from "@auth0/auth0-spa-js";

export enum UserRoles {
  Manager = "manager",
  Doctor = "doctor",
  OfficeAdmin = "office_admin",
  Testers = "testers",
}

interface IOtosSpecificFields extends User {
  "https://api.otoshealth.com/practice": string;
  "https://api.otoshealth.com/roles": UserRoles[];
  permissions: string[];
}

export const useAuthentication = () => {
  return useAuth0<IOtosSpecificFields>();
};

export const useRole = (): UserRoles[] => {
  const { user } = useAuthentication();
  if (!user) {
    throw new Error("useRole: No user found");
  }
  return user["https://api.otoshealth.com/roles"];
};

export const hasTesterRole = () => {
  return useRole().includes(UserRoles.Testers);
};

export * from "./Context";
