import { Control, useController } from "react-hook-form";
import { FormHelperText, FormLabel, Select } from "@chakra-ui/react";
import { useSMSTemplates } from "api/hooks/sms";

export const SMSTemplateSelect = ({
  control,
  fieldName = "templateID",
}: {
  control: Control<any, any>;
  fieldName?: string;
}) => {
  const { data: SMSTemplates } = useSMSTemplates();

  const defaultSMSTemplateId = SMSTemplates?.find(
    ({ isDefault }) => isDefault,
  )?.id;

  const {
    field: { onChange, value, ref },
  } = useController({
    control,
    name: fieldName,
    defaultValue: defaultSMSTemplateId,
  });

  return (
    <>
      <FormLabel>Message Template</FormLabel>
      <Select
        value={value}
        ref={ref}
        aria-label="sms template select"
        onChange={(e) => onChange(e.target.value, fieldName)}
      >
        {SMSTemplates?.map((template, key) => (
          <option
            key={key}
            value={template.id}
            aria-label={`${template.name} option`}
          >
            {template.name}
          </option>
        ))}
      </Select>
      <FormHelperText>
        The template for this text message (optional).
      </FormHelperText>
    </>
  );
};
