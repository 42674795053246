import { Route, Routes } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Spacer,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthentication } from "@packages/auth";
import { useAuthToken } from "@packages/auth";
import { OHIcon } from "@packages/theme/OHIcon";
import { ColumnContextProvider } from "contexts/ColumnContext";
import { GenericModalsProvider } from "contexts/GenericModals";
import { DefaultLayout } from "views";

import { LoadingCard } from "components/ui/Loader";

import { useWS } from "./api/hooks/useWebsocket";

export default function App() {
  const { colorMode } = useColorMode();
  const { token, setToken } = useAuthToken();
  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    logout,
    getAccessTokenSilently,
  } = useAuthentication();

  // Auth0 explicit auth logic
  if (!isAuthenticated && !isLoading && !user) {
    loginWithRedirect();
  }

  useWS(token, user?.email);

  // Auth0 silent auth logic
  if (isLoading || !user) {
    return <LoadingCard tip logo description="" />;
  }

  // Check if user has permissions
  if (user["https://api.otoshealth.com/roles"]?.length < 1) {
    return (
      <div className="flex justify-center w-screen h-screen">
        <Container m="auto">
          <Box
            backgroundColor={colorMode === "light" ? "white" : ""}
            py="6"
            rounded="md"
            shadow="xl"
            m="auto"
            mt="-8"
            color="black"
            border={colorMode === "dark" ? "1px" : ""}
            borderColor={colorMode === "dark" ? "gray.300" : ""}
          >
            <Stack spacing="4" alignItems="center">
              <OHIcon className="w-48 mx-auto" />
              <Spacer />
              <p
                className={
                  "my-8 text-lg text-center font-semibold invert mix-blend-difference"
                }
              >
                Account not found
              </p>
              <p
                className={
                  "my-8 text-md text-center invert mix-blend-difference"
                }
              >
                Please ask your admin to add you to a practice.
              </p>
              <Button
                colorScheme={"blue"}
                leftIcon={
                  <FontAwesomeIcon icon={["fas", "arrow-right-from-bracket"]} />
                }
                onClick={() => {
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  });
                }}
              >
                Switch accounts
              </Button>
            </Stack>
          </Box>
        </Container>
      </div>
    );
  }

  // Check if we need to set token before rendering anything
  if (token === "") {
    getAccessTokenSilently()
      .then((token) => {
        setToken(token);
      })
      .catch(console.error);
    return null;
  }

  if (token !== "") {
    return (
      <GenericModalsProvider>
        <ColumnContextProvider>
          <Routes>
            <Route path="/*" element={<DefaultLayout />} />
          </Routes>
        </ColumnContextProvider>
      </GenericModalsProvider>
    );
  }
  return null;
}
