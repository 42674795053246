import { createContext, ReactNode, useContext } from "react";
import { useToast, UseToastOptions } from "@chakra-ui/react";

interface IErrorAlertContext {
  error: (errorMessage?: UseToastOptions) => void;
}

export const ErrorAlertContext = createContext<IErrorAlertContext>({
  error: () => {
    return;
  },
});

export function useError(): IErrorAlertContext {
  const ctx = useContext(ErrorAlertContext);
  if (ctx === undefined) {
    throw new Error("useError must be used within a ErrorAlertContextProvider");
  }
  return ctx as IErrorAlertContext;
}

const DEFAULT_ERROR_MESSAGE: UseToastOptions = {
  title: "Oh no!",
  description:
    "Looks like we are having some connectivity issues, try refreshing this page.",
  status: "error",
  duration: 15000,
};

export function ErrorAlertContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const toast = useToast();

  const error = (errorMessage?: UseToastOptions) =>
    toast({ ...DEFAULT_ERROR_MESSAGE, ...errorMessage });

  return (
    <ErrorAlertContext.Provider value={{ error }}>
      {children}
    </ErrorAlertContext.Provider>
  );
}
