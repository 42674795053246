// static stack names
export const STACK_APP_NAME = "otos-app";
export const APP_NAME = "otoshealth";
export const SNS_SMS_DELIVERY_LOG_GROUP = `sns/${process.env.CDK_DEFAULT_REGION}/${process.env.CDK_DEFAULT_ACCOUNT}/DirectPublishToPhoneNumber`;

export const PATIENTS_EXPIRE_IN_HOURS = "48";

export const SMS_MAX_LENGTH = "1000";

export const PATIENT_SMS_DEFAULT_MESSAGE =
  "Hi! Pioneer Health (your doctor) has requested you complete a form. {{link}}";

export const GITHUB_DEPLOYMENT_ROLE_NAME = "github-deployment-role";
