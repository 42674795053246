import { HashRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthContextProvider } from "@packages/auth";
import config from "@packages/config";
import { theme } from "@packages/theme";
import { ErrorAlertContextProvider } from "contexts/AlertContext";
import { GlobalModalStateProvider } from "contexts/GlobalModalState";

import "@fortawesome/fontawesome-free/js/all";

import App from "./App";

// CSS
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";

export const BaseApp = () =>
  config?.doctorClientID?.length > 1 ? (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.doctorClientID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.auth0Audience,
      }}
      useRefreshTokens
    >
      <ChakraProvider theme={theme}>
        <HashRouter>
          <ErrorAlertContextProvider>
            <GlobalModalStateProvider>
              <AuthContextProvider>
                <App />
              </AuthContextProvider>
            </GlobalModalStateProvider>
          </ErrorAlertContextProvider>
        </HashRouter>
      </ChakraProvider>
    </Auth0Provider>
  ) : (
    <p>Error: Auth0 CID missing</p>
  );
