import { getTranscriptionUsage, TranscriptionUsage } from "@packages/api";
import { useAuthToken } from "@packages/auth";
import { useQuery } from "@tanstack/react-query";

export function useUsage(email: string) {
  const { token } = useAuthToken();
  return useQuery<TranscriptionUsage, undefined>(
    ["transcription-usage"],
    async () => await getTranscriptionUsage({ token, email }),
    {
      refetchOnWindowFocus: true,
    },
  );
}
