import { Edge } from "react-flow-renderer";
import { FieldTypes, IFlowItem, IForm, IMedspecField } from "@packages/types";

export enum ConnectionTypes {
  DefaultDest = "Default Destination",
  Conditional = "Conditional",
}

export enum FormTabs {
  FormDetails = "details",
  Slides = "slides",
  Conditions = "conditions",
  Flow = "flow",
}

export const FormTabCleanNames = {
  [FormTabs.FormDetails]: "Manage Form Details",
  [FormTabs.Slides]: "Manage Slides",
  [FormTabs.Conditions]: "Manage Conditions",
  [FormTabs.Flow]: "Manage Flow",
};

// Find a flow and rule definition using data from a React Flow edge
export function findRuleKeyByEdge(edge: Edge, form: IForm): number | undefined {
  const flow = findFlowByEdge(edge, form);
  if (flow) {
    const ruleKey = flow.rules.findIndex(
      (rule) => rule.id === edge.data.rule.id,
    );
    if (ruleKey > -1) {
      return ruleKey;
    }
  }
  return;
}

// Find a flow definition using data from a React Flow edge
export function findFlowByEdge(edge: Edge, form: IForm): IFlowItem | undefined {
  return form.flow?.find((item) => item.src === edge.source);
}

// Get slide id using the provided key and form
export function slideNameLookup(key?: string, form?: IForm): string {
  if (form && key) {
    if (key !== "end") {
      if (form !== undefined) {
        if (form.slides[key] !== undefined) {
          return form.slides[key].name;
        }
      }
    } else {
      return "End";
    }
  }
  return "";
}

// Get field using the provided key and form
export function fieldLookup(
  key?: string,
  form?: IForm,
): IMedspecField | undefined {
  if (form && key) {
    if (key.split(".").length > 1) {
      const [slide, field] = key.split(".");
      if (form.slides[slide]) {
        return form.slides[slide].fields?.find((f) => f.id === field);
      }
    }
  }
  return;
}

// Retrieve a field using an accessor string
export function getFieldDetailsByAccessor(
  accessor: string,
  form?: IForm,
  slideID?: string,
) {
  if (form) {
    let [slideId, fieldId] = ["", ""];
    if (accessor.split(".").length > 1) {
      [slideId, fieldId] = accessor.split(".");
    } else {
      [slideId, fieldId] = [slideID ?? "", accessor];
    }
    if (form.slides[slideId] && form.slides[slideId].fields) {
      return {
        slideName: form.slides[slideId].name,
        field: form.slides[slideId].fields?.find(
          (field) => field.id === fieldId,
        ),
      };
    }
  }
  return;
}

// Determine if field is of type text with choices
export function textWithChoices(field: IMedspecField | undefined): boolean {
  if (field) {
    if (field.type === FieldTypes.Text && field.choices) {
      if (field.choices.length > 0) {
        return true;
      }
    }
  }
  return false;
}
