// Config file for the integration-config-app
// Kept here for sake of maintanability

import globalVars, { getAPIURL } from "./globalVars";

interface NativeConfig {
  tenant: string;
  clientID: string;
  domain: string;
  audience: string;
  v2Endpoint: string;
}

function getEnv(): "dev" | "prod" {
  // if (process.env.VITE_DEV_SERVER_URL) {
  //   return 'dev';
  // }
  return "dev";
}

export function getConfig(): NativeConfig {
  const env = getEnv();
  const { v2Endpoint } = getAPIURL(env);
  return {
    tenant: globalVars.v2Api.tenant,
    clientID:
      env === "dev"
        ? "Q0ogDqW7oIlefgI1xjvIlV94rVTLHDH8"
        : process.env.AUTH0_CID ?? "",
    domain:
      env === "dev"
        ? globalVars.auth.auth0DevDomain
        : globalVars.auth.auth0ProdDomain,
    audience: globalVars.auth.auth0Audience,
    v2Endpoint,
  };
}

export default getConfig();
