import * as Sentry from "@sentry/react";

interface Prop {
  release: string;
  dsn: string;
}
const sentryInit = ({ dsn, release }: Prop) => {
  if (location.hostname === "localhost") {
    return;
  }

  const environment = location.hostname.includes("pr-")
    ? "PR"
    : location.hostname;

  Sentry.init({
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [import.meta.env.VITE_API_URL],
      }),
      new Sentry.Replay({
        blockAllMedia: true,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment,
    release,
  });
};
export default sentryInit;
