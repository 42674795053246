import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Heading,
  SimpleGrid,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { DateTime } from "luxon";

import { useUsage } from "../../api/hooks/transcription";

export const Usage = () => {
  const { user } = useAuth0();
  const usage = useUsage(user?.email || "");

  return (
    <Stack spacing="10" pt="10px">
      <Stack>
        <h2>Overview</h2>

        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: "5", md: "6" }}>
          <Box
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
            bg="bg.surface"
            borderRadius="lg"
            boxShadow="sm"
          >
            <Stack>
              <Text textStyle="sm" color="fg.muted">
                Cost:
              </Text>
              {usage.isLoading || !usage.data ? (
                <Skeleton height="40px" width="200px" />
              ) : (
                <Heading size={{ base: "sm", md: "md" }}>
                  ${usage.data.summary.usageFeeAud.toFixed(2)}
                </Heading>
              )}
            </Stack>
          </Box>
          <Box
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
            bg="bg.surface"
            borderRadius="lg"
            boxShadow="sm"
          >
            <Stack>
              <Text textStyle="sm" color="fg.muted">
                Transcribed:
              </Text>
              {usage.isLoading || !usage.data ? (
                <Skeleton height="40px" width="200px" />
              ) : (
                <Heading size={{ base: "sm", md: "md" }}>
                  {usage.data.summary.durationInWords ||
                    "Nothing transcribed yet"}
                </Heading>
              )}
            </Stack>
          </Box>
        </SimpleGrid>
      </Stack>
      <Stack>
        <h2>Usage history</h2>
        {usage.isLoading ? (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        ) : (
          <TableContainer overflowY={"auto"} maxH={500}>
            <Table variant="simple">
              <Thead position="sticky" top={-1} zIndex="docked">
                <Tr>
                  <Th>Date</Th>
                  <Th>Cost</Th>
                  <Th>Type</Th>
                </Tr>
              </Thead>
              <Tbody>
                {usage?.data?.usage.map((item, index) => (
                  <Tr key={index}>
                    <Td>
                      {DateTime.fromISO(item.createdAt).toFormat(
                        "ccc, dd LLL yyyy",
                      )}
                    </Td>
                    <Td>${item.usageFeeAud.toFixed(2)}</Td>
                    <Td>{item.type}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Stack>
  );
};
