import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Stack, useToast } from "@chakra-ui/react";
import { PaymentIntent } from "@packages/types";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useQueryClient } from "@tanstack/react-query";

import { MINIMUM_AMOUNT } from "../../../constants";
import { useCreatePaymentIntent } from "../../api/hooks/payment";

type Props = PaymentIntent;
export default function PaymentForm({ amount, currency }: Props) {
  const queryClient = useQueryClient();
  const [isComplete, setIsComplete] = useState(false);
  const stripe = useStripe();
  const { user } = useAuth0();
  const elements = useElements();
  const paymentIntent = useCreatePaymentIntent();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const toast = useToast();

  if (!user?.email) {
    return <div>Not logged in</div>;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { clientSecret } = await paymentIntent.mutateAsync({
      amount: amount * 100,
      currency,
      email: user?.email as string,
      name: user?.name as string,
    });
    await elements.submit();

    const { paymentIntent: paymentIntentResponse, error } =
      await stripe.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/#/settings/billing`,
          payment_method_data: {
            billing_details: {
              email: user?.email,
              name: user?.name,
              address: {
                country: "AU",
              },
            },
          },
        },
      });

    setIsProcessing(false);
    if (paymentIntentResponse?.status === "succeeded") {
      // update balance cache
      queryClient.setQueryData(["payment-balance"], (cachedData) => {
        if (!cachedData) {
          return cachedData;
        }
        const { balance } = cachedData as { balance: number };
        const newBalance = balance + amount * 100;
        return {
          balance: newBalance,
          formattedBalance: (newBalance / 100).toFixed(2),
        };
      });
      toast({
        id: paymentIntentResponse.id,
        title: "Payment successful",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
    } else {
      toast({
        id: paymentIntentResponse?.id,
        title: error?.message || "An unexpected error occurred",
        status: "warning",
        duration: 7000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Stack
        id="payment-form"
        style={{
          width: "100%",
        }}
        gap={{ base: "3" }}
      >
        <PaymentElement
          id="payment-element"
          options={{
            layout: { type: "accordion" },
            fields: {
              billingDetails: {
                address: {
                  country: "never",
                  postalCode: "auto",
                },
              },
            },
          }}
          onChange={({ complete }) => {
            setIsComplete(complete);
          }}
          onReady={() => {
            setIsReady(true);
          }}
        />
      </Stack>

      {isReady && (
        <Button
          isDisabled={
            !isComplete ||
            isProcessing ||
            !stripe ||
            !elements ||
            amount < MINIMUM_AMOUNT
          }
          isLoading={isProcessing}
          onClick={handleSubmit}
          w={{ base: "full" }}
        >
          Purchase Credit ${amount}
        </Button>
      )}
    </>
  );
}
